import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import FieldLevelForm from '../../components/FieldLevelForm';
import { createLevel, modifyLevel } from '../../state/actions/fieldLevels';
import firebase from '../../firebase';
import paths from '../Router/paths';

const FieldLevel = () => {
  const { id } = useParams();

  const { success, isAdmin } = useSelector(
    state => ({
      success: state.fieldLevels.success,
      isAdmin: state.auth.userData.isAdmin
    }),
    shallowEqual
  );

  const [level, setLevel] = useState({
    name: '',
    countryId: 'SG',
    isObsolete: false,
    createdAt: new Date().toUTCString(),
    order: 0,

  });



  useEffect(() => {
    const fetchLevelData = async () => {
      const response = (
        await firebase
          .database()
          .ref(`fieldLevels/${id}`)
          .once('value')
      ).val();
      return response;
    };
    if (id) {
      fetchLevelData()
        .then(levelData => {
          setLevel({
            ...levelData,
            createdAt: levelData.createdAt,
            id
          });
        })
        .catch(() => {
          setLevel({ error: true });
        });
    }

  }, [id]);

  const isEditing = !!id;
  const redirectAdmin = !isAdmin && <Redirect to={paths.ROOT}/>;
  const redirect = (level.error || success) && <Redirect to={paths.MANAGE_FIELD_LEVEL}/>;
  const levelForm =
    !level.name && id ? (
      <ClipLoader/>
    ) : (
      <FieldLevelForm
        levelData={level}
        action={isEditing ? modifyLevel : createLevel}
      />
    );
  return (
    <>
      {redirectAdmin}
      {redirect}
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">{isEditing ? 'Edit Level' : 'Add Level'}</Card.Title>
            </Card.Header>
            <Card.Body>
              {levelForm}
            </Card.Body>

          </Card>
        </Col>
      </Row>
    </>
  );
};

export default FieldLevel;
