import { createReducer } from 'redux-act';

import {
  PREFERENCES_SET_LOCALE,
  COLLAPSE_MENU,
  COLLAPSE_TOGGLE,
  CHANGE_LAYOUT,
  NAV_CONTENT_LEAVE,
  NAV_COLLAPSE_LEAVE
} from 'state/actions/preferences';
import config from '../../../config';

const initialState = {
  locale: null,
  isOpen: [], // for active default menu
  isTrigger: [], // for active default menu, set blank for horizontal
  ...config,
  isFullScreen: false, // static can't change
};

export const preferencesReducer = createReducer(
  {
    [PREFERENCES_SET_LOCALE]: (state, payload) => ({
      ...state,
      locale: payload.locale
    }),
    [COLLAPSE_MENU]: (state) => ({
      ...state,
      collapseMenu: !state.collapseMenu
    }),
    [COLLAPSE_TOGGLE]: (state, payload) => {
      let isOpen = [];
      let isTrigger = [];
      if (payload.menu.type === 'sub') {
        isOpen = state.isOpen;
        isTrigger = state.isTrigger;

        const triggerIndex = isTrigger.indexOf(payload.menu.id);
        if (triggerIndex > -1) {
          isOpen = isOpen.filter(item => item !== payload.menu.id);
          isTrigger = isTrigger.filter(item => item !== payload.menu.id);
        }

        if (triggerIndex === -1) {
          isOpen = [...isOpen, payload.menu.id];
          isTrigger = [...isTrigger, payload.menu.id];
        }
      } else {
        isOpen = state.isOpen;
        const triggerIndex = (state.isTrigger).indexOf(payload.menu.id);
        isTrigger = (triggerIndex === -1) ? [payload.menu.id] : [];
        isOpen = (triggerIndex === -1) ? [payload.menu.id] : [];
      }

      return {
        ...state,
        isOpen,
        isTrigger
      };
    },
    [NAV_CONTENT_LEAVE]: (state) =>  ({
      ...state,
      isOpen: [],
      isTrigger: [],
    }),
    [NAV_COLLAPSE_LEAVE]: (state, payload) => {
      let isTrigger = [];
      let isOpen = [];
      if (payload.menu.type === 'sub') {
        isOpen = state.isOpen;
        isTrigger = state.isTrigger;

        const triggerIndex = isTrigger.indexOf(payload.menu.id);
        if (triggerIndex > -1) {
          isOpen = isOpen.filter(item => item !== payload.menu.id);
          isTrigger = isTrigger.filter(item => item !== payload.menu.id);
        }
        return {
          ...state,
          isOpen,
          isTrigger,
        };
      }
      return {...state};
    },
    [CHANGE_LAYOUT]: (state, payload) =>  ({
      ...state,
      layout: payload.layout
    }),
  },
  initialState
);
