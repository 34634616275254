import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form } from 'react-bootstrap';

import paths from 'pages/Router/paths';
import { levelsCleanUp, clearLevelsData } from 'state/actions/browsingLevels';
import { useFormatMessage } from 'hooks';
import { fetchCountries } from '../../state/actions/countries';

const FieldSubjectForm = ({ levelData, action }) => {
  const { countries, loading } = useSelector(
    state => ({
      countries: state.countries.data,
      loading: state.levels.loading
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCountries());
    if (!levelData.id) dispatch(clearLevelsData());
    return () => dispatch(levelsCleanUp());
  }, [dispatch, levelData.id]);

  const [level, setLevel] = useState(levelData);

  const onChangeHandler = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setLevel(prevState => {
      const newValue = prevState;
      newValue[`${name}`] = value;
      return { ...newValue };
    });
  };

  const onSubmitHandler = event => {
    event.preventDefault();
    dispatch(
      action({ ...level })
    );
  };

  const canSubmit = level.name && level.countryId;

  const goBackMessage = useFormatMessage('UserForm.goBack');

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <Card.Body>
            <form onSubmit={onSubmitHandler}>
              <Row>
                <Col >
                  <h5>Basic Information</h5>
                  <hr/>
                  <Form.Group>
                    <Form.Label htmlFor="level-name">
                      Level Name *
                    </Form.Label>
                    <Form.Control
                      id="level-name"
                      type="text"
                      required
                      name="name"
                      value={level.name}
                      onChange={onChangeHandler}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label htmlFor="level-countryId">Country *</Form.Label>
                    <Form.Control
                      id="level-countryId"
                      as="select"
                      name="countryId"
                      required
                      value={level.countryId}
                      onChange={onChangeHandler}
                    >
                      {Object.values(countries).map((value) => <option key={value.code} value={value.code}>{value.name}</option>)}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group>
                    <Form.Check type="checkbox" onChange={onChangeHandler} checked={level.isObsolete}
                                name="isObsolete" id="level-isObsolete" label="Is Obsolete"/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="level-order">
                      Order
                    </Form.Label>
                    <Form.Control
                      id="level-order"
                      type="text"
                      name="order"
                      value={level.order}
                      onChange={onChangeHandler}
                    />
                  </Form.Group>
                  <hr/>
                  <Form.Group>
                    <button
                      type="submit"
                      className={`btn btn-primary ${loading &&
                      'is-loading'}`}
                      disabled={!canSubmit}
                    >
                      <span>{useFormatMessage('UserForm.submit')}</span>
                    </button>
                    <Link to={paths.MANAGE_FIELD_LEVEL} className="button">
                      {goBackMessage}
                    </Link>
                  </Form.Group>
                </Col>
              </Row>
            </form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

FieldSubjectForm.propTypes = {
  levelData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    countryId: PropTypes.string,
  }),
  action: PropTypes.func.isRequired
};

export default FieldSubjectForm;
