import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form } from 'react-bootstrap';

import paths from 'pages/Router/paths';
import { levelsCleanUp, clearLevelsData } from 'state/actions/browsingLevels';
import { useFormatMessage } from 'hooks';
import './LevelForm.scss';
import { accessByOptions } from '../options';

const BrowsingLevelForm = ({ levelData, action }) => {
  const { loading } = useSelector(
    state => ({
      loading: state.levels.loading
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!levelData.id) dispatch(clearLevelsData());
    return () => dispatch(levelsCleanUp());
  }, [dispatch, levelData.id]);

  const [level, setLevel] = useState(levelData);
  const defaultCheckedAddress = levelData.fields.contact &&
    levelData.fields.email &&
    levelData.fields.houseNumber &&
    levelData.fields.buildingName &&
    levelData.fields.unitNumber &&
    levelData.fields.street1 &&
    levelData.fields.street2 &&
    levelData.fields.postcode;
  const [checkedAddress, toggleAddress] = useState(defaultCheckedAddress);

  const onChangeHandler = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name, dataset } = target;
    setLevel(prevState => {
      const newValue = prevState;
      if (dataset && dataset.fields) {
        newValue.fields[`${name}`] = value;
      } else {
        newValue[`${name}`] = value;
      }
      return { ...newValue };
    });
  };

  const onToggleSelectAllHandler = (event) => {
    const { target } = event;
    let isChecked = target.checked;
    toggleAddress(prevState => {
      isChecked = !prevState;
      return !prevState;
    });
    setLevel(prevState => {
      const newValue = prevState;
      newValue.fields.contact = !!isChecked;
      newValue.fields.email = !!isChecked;
      newValue.fields.houseNumber = !!isChecked;
      newValue.fields.buildingName = !!isChecked;
      newValue.fields.unitNumber = !!isChecked;
      newValue.fields.street1 = !!isChecked;
      newValue.fields.street2 = !!isChecked;
      newValue.fields.postcode = !!isChecked;
      return { ...newValue };
    });
  };

  const onSubmitHandler = event => {
    event.preventDefault();
    dispatch(
      action({ ...level })
    );
  };

  const canSubmit = level.name && level.accessBy && level.description;

  const goBackMessage = useFormatMessage('UserForm.goBack');

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <Card.Body>
            <form onSubmit={onSubmitHandler}>
              <Row>
                <Col md={6}>
                  <h5>Basic Information</h5>
                  <hr/>
                  <Form.Group>
                    <Form.Label htmlFor="level-name">
                      Level Name *
                    </Form.Label>
                    <Form.Control
                      id="level-name"
                      type="text"
                      required
                      name="name"
                      value={level.name}
                      onChange={onChangeHandler}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label htmlFor="level-accessBy">Access By *
                    </Form.Label>
                    <Form.Control
                      id="level-accessBy"
                      as="select"
                      name="accessBy"
                      required
                      value={level.accessBy}
                      onChange={onChangeHandler}
                    >
                      {Object.keys(accessByOptions).map((key) => <option key={key} value={key}>{accessByOptions[key]}</option>)}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label htmlFor="level-description">Description *</Form.Label>
                    <Form.Control
                      id="level-description"
                      name="description"
                      as="textarea"
                      value={level.description}
                      onChange={onChangeHandler}
                      required
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label htmlFor="level-order">
                      Order
                    </Form.Label>
                    <Form.Control
                      id="level-order"
                      type="text"
                      name="order"
                      value={level.order}
                      onChange={onChangeHandler}
                    />
                  </Form.Group>

                  <hr/>
                  <Form.Group>
                    <button
                      type="submit"
                      className={`btn btn-primary ${loading &&
                      'is-loading'}`}
                      disabled={!canSubmit}
                    >
                      <span>{useFormatMessage('UserForm.submit')}</span>
                    </button>
                    <Link to={paths.MANAGE_LEVEL} className="button">
                      {goBackMessage}
                    </Link>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <h5>Show or hide fields</h5>
                  <hr/>
                  <Form.Group>
                    <p className="form-label">Tutor (for student view)
                    </p>
                    <Form.Check type="checkbox" onChange={onToggleSelectAllHandler} checked={checkedAddress}
                                name="address" id="level-address" label="Select All"/>

                    <div className="ml-3">
                      <Form.Check type="checkbox" onChange={onChangeHandler} checked={level.fields.contact}
                                  name="contact" id="level-contact" label="Contact" data-fields/>
                      <Form.Check type="checkbox" onChange={onChangeHandler} checked={level.fields.email}
                                  name="email" id="level-email" label="Email" data-fields/>
                    </div>
                  </Form.Group>
                  <Form.Group className="ml-3">
                    <h6>Address</h6>

                    <Form.Check type="checkbox" onChange={onChangeHandler} checked={level.fields.houseNumber}
                                name="houseNumber" id="level-houseNumber" label="House/blk Number" data-fields/>
                    <Form.Check type="checkbox" onChange={onChangeHandler} checked={level.fields.buildingName}
                                name="buildingName" id="level-buildingName" label="Building Name" data-fields/>
                    <Form.Check type="checkbox" onChange={onChangeHandler} checked={level.fields.unitNumber}
                                name="unitNumber" id="level-unitNumber" label="Unit number (if not house)" data-fields/>
                    <Form.Check type="checkbox" onChange={onChangeHandler} checked={level.fields.street1}
                                name="street1" id="level-street1" label="Street Line 1" data-fields/>
                    <Form.Check type="checkbox" onChange={onChangeHandler} checked={level.fields.street2}
                                name="street2" id="level-street2" label="Street Line 2" data-fields/>
                    <Form.Check type="checkbox" onChange={onChangeHandler} checked={level.fields.postcode}
                                name="postcode" id="level-postcode" label="Postal Code" data-fields/>
                  </Form.Group>

                </Col>
              </Row>
            </form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

BrowsingLevelForm.propTypes = {
  levelData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    accessBy: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    fields: PropTypes.object.isRequired
  }),
  action: PropTypes.func.isRequired
};

export default BrowsingLevelForm;
