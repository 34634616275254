import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Card } from 'react-bootstrap';

import { useFormatMessage } from 'hooks';
import UserForm from 'components/UserForm';
import { modifyUser } from 'state/actions/users';
import ChangePassword from './ChangePassword';

const Profile = () => {
  const { userData } = useSelector(
    state => ({
      userData: state.auth.userData
    }),
    shallowEqual
  );

  return (
    <Card>
      <Card.Header>
        <div className='d-flex flex-row align-items-center'>
          <Card.Title as="h5">{useFormatMessage('Profile.profile')}</Card.Title>
        </div>
      </Card.Header>
      <Card.Body>
        <UserForm isEditing isProfile userData={userData} action={modifyUser}/>
        <ChangePassword/>
      </Card.Body>
    </Card>

  );
};

export default Profile;
