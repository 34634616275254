export default {
  ROOT: '/',
  LOGIN: '/login',
  USERS: '/users',
  ADD_USER: '/users/new',
  MODIFY_USER: '/users/:id',
  PROFILE: '/profile',
  RESET_PASSWORD: '/recover-password',
  SECTION: '/section',
  ADD_LEVEL: '/browsing-levels/new',
  MODIFY_LEVEL: '/browsing-levels/:id',
  MANAGE_LEVEL: '/browsing-levels',
  MANAGE_COUNTRY: '/countries',
  MANAGE_FIELD_LEVEL: '/field-levels',
  MODIFY_FIELD_LEVEL: '/field-levels/:id',
  ADD_FIELD_LEVEL: '/field-levels/new',
  MANAGE_SUBJECT: '/subjects',
  MODIFY_SUBJECT: '/subjects/:id',
  ADD_SUBJECT: '/subjects/new',
  MANAGE_FIELD_SCHOOL: '/field-schools',
  MODIFY_FIELD_SCHOOL: '/field-schools/:id',
  ADD_FIELD_SCHOOL: '/field-schools/new',
  MANAGE_STUDENT: '/students',
  MODIFY_STUDENT: '/students/:id',
  ADD_STUDENT: '/students/new',
  MANAGE_TUTOR: '/tutors',
  MODIFY_TUTOR: '/tutors/:id',
  ADD_TUTOR: '/tutors/new',
  STATISTIC: '/statistic',
  CHART: '/chart/:data',
  ORDERS: '/orders',
  ORDER: '/orders/:id',
  QR_CODE: '/qrcode/:id',
  PAYMENT_INTRUCTION: '/payment/:id/:total'
};
