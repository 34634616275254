import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form } from 'react-bootstrap';

import paths from 'pages/Router/paths';
import { schoolsCleanUp, clearSchoolsData } from 'state/actions/schools';
import { useFormatMessage } from 'hooks';
import { fetchCountries } from '../../state/actions/countries';

const FieldSchoolForm = ({ schoolData, action }) => {
  const { countries, loading } = useSelector(
    state => ({
      countries: state.countries.data,
      loading: state.schools.loading
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCountries());
    if (!schoolData.id) dispatch(clearSchoolsData());
    return () => dispatch(schoolsCleanUp());
  }, [dispatch, schoolData.id]);

  const [school, setSchool] = useState(schoolData);

  const onChangeHandler = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setSchool(prevState => {
      const newValue = prevState;
      newValue[`${name}`] = value;
      return { ...newValue };
    });
  };

  const onSubmitHandler = event => {
    event.preventDefault();
    dispatch(
      action({ ...school })
    );
  };

  const canSubmit = school.name && school.countryId;

  const goBackMessage = useFormatMessage('UserForm.goBack');

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <Card.Body>
            <form onSubmit={onSubmitHandler}>
              <Row>
                <Col>
                  <h5>Basic Information</h5>
                  <hr/>
                  <Form.Group>
                    <Form.Label htmlFor="school-name">
                      School Name *
                    </Form.Label>
                    <Form.Control
                      id="school-name"
                      type="text"
                      required
                      name="name"
                      value={school.name}
                      onChange={onChangeHandler}
                    />
                  </Form.Group>


                  <Form.Group>
                    <Form.Label htmlFor="school-country">Country *
                    </Form.Label>
                    <Form.Control
                      id="school-country"
                      as="select"
                      name="countryId"
                      required
                      value={school.countryId}
                      onChange={onChangeHandler}
                    >
                      {Object.values(countries).map((value) => <option key={value.code}
                                                                       value={value.code}>{value.name}</option>)}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label htmlFor="school-address1">
                      Address 1
                    </Form.Label>
                    <Form.Control
                      id="school-address1"
                      type="text"
                      name="address1"
                      value={school.address1}
                      onChange={onChangeHandler}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label htmlFor="school-address2">
                      Address 2
                    </Form.Label>
                    <Form.Control
                      id="school-address2"
                      type="text"
                      name="address2"
                      value={school.address2}
                      onChange={onChangeHandler}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label htmlFor="school-postcode">
                      Postal code
                    </Form.Label>
                    <Form.Control
                      id="school-postcode"
                      type="text"
                      name="postcode"
                      value={school.postcode}
                      onChange={onChangeHandler}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Check type="checkbox" onChange={onChangeHandler} checked={school.isObsolete}
                                name="isObsolete" id="school-isObsolete" label="Is Obsolete"/>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label htmlFor="school-order">
                      Order
                    </Form.Label>
                    <Form.Control
                      id="school-order"
                      type="text"
                      name="order"
                      value={school.order}
                      onChange={onChangeHandler}
                    />
                  </Form.Group>
                  <hr/>
                  <Form.Group>
                    <button
                      type="submit"
                      className={`btn btn-primary ${loading &&
                      'is-loading'}`}
                      disabled={!canSubmit}
                    >
                      <span>{useFormatMessage('UserForm.submit')}</span>
                    </button>
                    <Link to={paths.MANAGE_LEVEL} className="button">
                      {goBackMessage}
                    </Link>
                  </Form.Group>
                </Col>
              </Row>
            </form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

FieldSchoolForm.propTypes = {
  schoolData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    countryId: PropTypes.string
  }),
  action: PropTypes.func.isRequired
};

export default FieldSchoolForm;
