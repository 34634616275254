import React from 'react';
import { Radar } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import { Alert, Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const results = require('../../assets/testResults.json');

const TestResult = (props) => {
  const dispatch = useDispatch();
  const { userData, action } = props;
  let { data } = useParams();
  const testResult = userData && userData.testResult;
  if (!data && testResult) {
    data = [];
    Object.values(testResult).forEach(item => {
      let totalScore = 0;
      Object.values(item).forEach(score => {
        totalScore += score;
      });
      data.push(totalScore);
    });
  }
  if (!data) return <Alert variant='warning'>This user has not completed the multiple intelligence test yet!</Alert>;
  data = decodeURIComponent(data).split(',');
  const chartData = {
    labels: ['Linguistic', 'Mathematical', 'Musical', 'Visual', 'Kinesthetic', 'Interpersonal', 'Intrapersonal', 'Naturalistic'],
    datasets: [
      {
        label: 'Result',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        pointBackgroundColor: 'rgba(255,99,132,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(255,99,132,1)',
        data: decodeURIComponent(data).split(',')
      }
    ]
  };
  const handleReset = () => {
    if (testResult) {
      userData.isTested = null;
      userData.testResult = null;
      dispatch(
        action({ ...userData })
      );
    }
  };

  const sortData = data.map((value, index) => ({ index, value }));
  sortData.sort((a, b) => (-(parseInt(a.value, 10) - parseInt(b.value, 10))));
  const top3Results = sortData.slice(0, 3);
  return (
    <Card>
      {testResult &&
      <Card.Header>
        <div className='d-flex flex-row align-items-center'>
          <Button onClick={() => handleReset()} className="btn btn-dark ml-auto">
            Reset
          </Button>
        </div>
      </Card.Header>
      }
      <Card.Body>
        <Row>
          <Col md={6}>
            <Radar data={chartData}
                   width={50}
                   height={50}
                   options={{
                     scale: {
                       ticks: {
                         beginAtZero: true,
                         max: 18,
                         min: 0,
                         stepSize: 2
                       }
                     }
                   }}
            />
          </Col>
          <Col md={6} style={{ alignSelf: 'center' }}>
            <Table responsive="sm">
              <thead>
              {top3Results.map((value) =>
              <tr key={value.index} >
                  <th
                      style={{
                        backgroundColor: `${results[value.index].color}`,
                        textAlign: 'center'
                      }}>{results[value.index].name}</th>
              </tr>
              )}
              </thead>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>

  );
};

export default TestResult;
