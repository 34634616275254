import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage, useFormatDate } from 'hooks';
import Table from 'components/Table';
import { fetchUsers, deleteUser, clearUsersData } from 'state/actions/students';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import NavSearch from '../../components/Layout/NavSearch';
import { sortDate } from '../../utils';

const Students = () => {
  const { usersList, isAdmin, error, loading, deleted } = useSelector(
    state => ({
      usersList: state.students.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.students.error,
      loading: state.students.loading,
      deleted: state.students.deleted
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    userId: null,
    isOpen: false
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState();

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchUsers());
    }

    return () => dispatch(clearUsersData());
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal(prevState => ({
        userId: null,
        isOpen: !prevState.isOpen
      }));
    }
  }, [deleted, loading]);

  const redirect = !isAdmin && <Redirect to={paths.ROOT}/>;

  const onRemoveButtonClickHandler = userId => {
    setDeleteModal(prevState => ({
      userId,
      isOpen: !prevState.isOpen
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ userId: null, isOpen: false });
  };

  const onDeleteUserHandler = () => {
    dispatch(deleteUser(deleteModal.userId));
  };

  const columns = [
    {
      Header: '',
      accessor: 'logoUrl',
      Cell: ({ row }) => (
        <div className="image">
          <img src={row.original.logoUrl} alt="" style={{ width: '40px' }} className="rounded-circle"/>
        </div>
      ),
      disableSortBy: true
    },
    {
      Header: useFormatMessage('Users.name'),
      accessor: 'name'
    },
    {
      Header: useFormatMessage('Users.email'),
      accessor: 'email'
    },
    {
      Header: 'Gender',
      accessor: 'gender',
    },

    {
      Header: useFormatMessage('Users.created'),
      accessor: 'createdAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          })}
        </small>
      )
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          {!row.original.isAdmin && (
            <div className="button-actions is-right">
              <Link
                to={`/students/${row.original.id}`}
                className="btn btn-sm btn-primary"
              >
                <i className="feather icon-edit"/>
              </Link>

              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={() => onRemoveButtonClickHandler(row.original.id)}
              >
                <i className="feather icon-trash"/>
              </button>
            </div>
          )}
        </>
      ),
      disableSortBy: true
    }
  ];

  const data = search
    ? usersList.filter(el => {
      const clonedElem = { name: el.name, email: el.email, id: el.id };
      if (!clonedElem.name) {
        delete clonedElem.name;
      }
      return Object.values(clonedElem).some(field =>
        field.toLowerCase().includes(search.toLowerCase())
      );
    })
    : usersList;
  const deleteMessage = useFormatMessage('Users.delete');

  const confirmMessage = useFormatMessage('Users.confirm');

  const permDeleteMessage = useFormatMessage('Users.permDelete');

  const cancelMessage = useFormatMessage('Users.cancel');

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteUserHandler}
          onCancel={onCloseModalHandler}
        />
      )}
          <Card>
            <Card.Header>
              <div className='d-flex flex-row align-items-center'>
                <Card.Title as="h5">Manage Students</Card.Title>
                <NavSearch handleChange={setSearch} />
                <Link to={paths.ADD_STUDENT} className="btn btn-light ml-auto">
                  Add new Student
                </Link>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="b-table">
                {loading ? <ClipLoader/> : <Table columns={columns} data={data}/>}
                {error && 'Show error'}
              </div>
            </Card.Body>
          </Card>
    </>
);
};

export default Students;
