import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import firebase from 'firebase.js';

export const SCHOOL_FETCH_DATA_INIT = createAction('SCHOOL_FETCH_DATA_INIT');
export const SCHOOL_FETCH_DATA_SUCCESS = createAction(
  'SCHOOL_FETCH_DATA_SUCCESS'
);
export const SCHOOL_FETCH_DATA_FAIL = createAction('SCHOOL_FETCH_DATA_FAIL');

export const SCHOOL_DELETE_SCHOOL_INIT = createAction('SCHOOL_DELETE_SCHOOL_INIT');
export const SCHOOL_DELETE_SCHOOL_SUCCESS = createAction(
  'SCHOOL_DELETE_SCHOOL_SUCCESS'
);
export const SCHOOL_DELETE_SCHOOL_FAIL = createAction('SCHOOL_DELETE_SCHOOL_FAIL');

export const SCHOOL_CLEAR_DATA = createAction('SCHOOL_CLEAR_DATA');

export const SCHOOL_CREATE_SCHOOL_INIT = createAction('SCHOOL_CREATE_SCHOOL_INIT');
export const SCHOOL_CREATE_SCHOOL_SUCCESS = createAction(
  'SCHOOL_CREATE_SCHOOL_SUCCESS'
);
export const SCHOOL_CREATE_SCHOOL_FAIL = createAction('SCHOOL_CREATE_SCHOOL_FAIL');

export const SCHOOL_MODIFY_SCHOOL_INIT = createAction('SCHOOL_MODIFY_SCHOOL_INIT');
export const SCHOOL_MODIFY_SCHOOL_SUCCESS = createAction(
  'SCHOOL_MODIFY_SCHOOL_SUCCESS'
);
export const SCHOOL_MODIFY_SCHOOL_FAIL = createAction('SCHOOL_MODIFY_SCHOOL_FAIL');

export const SCHOOL_CLEAN_UP = createAction('SCHOOL_CLEAN_UP');

export const SCHOOL_CLEAR_DATA_LOGOUT = createAction('SCHOOL_CLEAR_DATA_LOGOUT');

export const fetchSchools = ( all = false) => {
  return async (dispatch) => {

    dispatch(SCHOOL_FETCH_DATA_INIT());

    let schools;

    try {
      schools = (
        await firebase
          .database()
          .ref('schools')
          .orderByChild('order')
          .once('value')
      ).val();
    } catch (error) {
      toastr.error('', error);
      return dispatch(SCHOOL_FETCH_DATA_FAIL({ error }));
    }
    const schoolsData = schools
      ? Object.values(schools)
      : [];
    const result = all ? schoolsData : schoolsData.filter(item => !item.isObsolete)
    return dispatch(
      SCHOOL_FETCH_DATA_SUCCESS({ schools: result })
    );
  };
};

export const deleteSchool = id => {
  return async (dispatch, getState) => {
    dispatch(SCHOOL_DELETE_SCHOOL_INIT());
    const { locale } = getState().preferences;

    const deleteSchoolTask = firebase
      .database()
      .ref(`schools/${id}`)
      .remove();

    try {
      await Promise.all([deleteSchoolTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SCHOOL_DELETE_SCHOOL_FAIL({
          error: errorMessage
        })
      );
    }

    toastr.success('', 'The school was deleted.');
    return dispatch(SCHOOL_DELETE_SCHOOL_SUCCESS({ id }));
  };
};

export const clearSchoolsData = () => {
  return dispatch => {
    dispatch(SCHOOL_CLEAR_DATA());
  };
};

export const clearSchoolsDataLogout = () => {
  return dispatch => {
    dispatch(SCHOOL_CLEAR_DATA_LOGOUT());
  };
};

export const createSchool = (data ) => {
  return async (dispatch, getState) => {
    dispatch(SCHOOL_CREATE_SCHOOL_INIT());
    const { locale } = getState().preferences;

    const uid = firebase.database().ref().child('schools').push().key;

    const school = {...data, id: uid}

    const createSchoolDbTask = firebase
      .database()
      .ref(`schools/${uid}`)
      .set(school);

    try {
      await Promise.all([
        createSchoolDbTask
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SCHOOL_CREATE_SCHOOL_FAIL({
          error: errorMessage
        })
      );
    }
    toastr.success('', 'School created successfully');
    return dispatch(SCHOOL_CREATE_SCHOOL_SUCCESS(school));
  };
};

export const modifySchool = (schoolData) => {
  return async (dispatch, getState) => {
    dispatch(SCHOOL_MODIFY_SCHOOL_INIT());
    const { locale } = getState().preferences;

    const updateSchoolDbTask = firebase
      .database()
      .ref(`schools/${schoolData.id}`)
      .update(schoolData);

    try {
      await Promise.all([updateSchoolDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SCHOOL_MODIFY_SCHOOL_FAIL({
          error: errorMessage
        })
      );
    }
    toastr.success('', 'School updated successfully');

    return dispatch(SCHOOL_MODIFY_SCHOOL_SUCCESS(schoolData));
  };
};

export const schoolsCleanUp = () => dispatch => dispatch(SCHOOL_CLEAN_UP());
