import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';

import paths from 'pages/Router/paths';
import { usersCleanUp } from 'state/actions/users';
import { useFormatMessage } from 'hooks';
import DatePicker from '../DatePicker';
import { fetchSchools } from '../../state/actions/schools';
import { fetchLevels } from '../../state/actions/fieldLevels';
import { fetchLevels as fetchBrowsingLevels } from '../../state/actions/browsingLevels';
import { fetchSubjects } from '../../state/actions/subjects';
import { booleanOptions, genderOptions, preferredTime, proficiencyOptions } from '../options';

const ProfileLevelForm = ({ userData, isTutor, action }) => {
  const { levels, fieldLevels, schools, subjects, loading } = useSelector(
    state => ({
      levels: state.levels.data,
      schools: state.schools.data,
      subjects: state.subjects.data,
      fieldLevels: state.fieldLevels.data,
      loading: state.users.loading
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLevels());
    dispatch(fetchBrowsingLevels());
    dispatch(fetchSchools());
    dispatch(fetchSubjects());
    return () => dispatch(usersCleanUp());
  }, [dispatch]);

  const [profileLevel, setProfileLevel] = useState(userData.profileLevel || {});

  const onFileChangedHandler = event => {
    const cv = event.target.files[0];
    setProfileLevel(prevState => ({ ...prevState, cv }));
  };

  const onChangeHandler = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setProfileLevel(prevState => {
      const newValue = prevState;
      newValue.subjects = prevState.subjects || {};
      if (name.includes('proficiency_')) {
        const subjectId = name.replace('proficiency_', '');
        if (subjectId && value) {
          newValue.subjects[subjectId] = value;
        }
      } else if (name.includes('levelStart_')) {
        const subjectId = name.replace('levelStart_', '');
        if (!newValue.subjects[subjectId]) {
          newValue.subjects[subjectId] = {};
        }
        if (subjectId && value) {
          newValue.subjects[subjectId].levelStart = value;
        }
      } else if (name.includes('levelEnd_')) {
        const subjectId = name.replace('levelEnd_', '');
        if (!newValue.subjects[subjectId]) {
          newValue.subjects[subjectId] = {};
        }
        if (subjectId && value) {
          newValue.subjects[subjectId].levelEnd = value;
        }
      } else if (name.includes('subjects_')) {
        const subjectId = name.replace('subjects_', '');
        if (subjectId && value) {
          if (isTutor) {
            newValue.subjects[subjectId] = {};
          } else {
            newValue.subjects[subjectId] = 'avg';
          }
        } else {
          delete newValue.subjects[subjectId];
        }
      } else {
        newValue[`${name}`] = value;
      }
      return { ...newValue };
    });
  };

  const onSubmitHandler = event => {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    delete userData.profileLevel;
    dispatch(
      action({ ...userData, profileLevel })
    );
  };

  const goBackMessage = useFormatMessage('UserForm.goBack');

  return (
    <form onSubmit={onSubmitHandler}>
      <Row>
        <Col lg={6} md={12}>
          {!isTutor &&
          <Form.Group>
            <Form.Group>
              <Form.Label>School Level
              </Form.Label>
              <Form.Control
                as="select"
                type="text"
                name="levelId"
                required
                value={profileLevel.levelId}
                onChange={onChangeHandler}
              >
                {Object.values(fieldLevels).map((value) => <option key={value.id}
                                                                   value={value.id}>{value.name}</option>)}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>School Name
              </Form.Label>
              <Form.Control
                as="select"
                type="text"
                name="schoolId"
                required
                value={profileLevel.schoolId}
                onChange={onChangeHandler}
              >
                {Object.values(schools).map((value) => <option key={value.id}
                                                               value={value.id}>{value.name}</option>)}
              </Form.Control>
            </Form.Group>
          </Form.Group>
          }
          <Form.Group>
            <Form.Label>Subjects</Form.Label>
            {Object.values(subjects).map((value) =>
              <Form.Group>
                <Form.Check
                  checked={profileLevel.subjects && Object.keys(profileLevel.subjects).includes(value.id)}
                  name={`subjects_${value.id}`}
                  label={value.name} type='checkbox' key={value.id} id={`subjects_${value.id}`}
                  onChange={onChangeHandler}
                />
                {isTutor ?
                  <Form.Group className='ml-3'>
                    <Row className='mt-2'>
                      <Col>
                        <Form.Group>
                          <Form.Label>Level Start</Form.Label>
                          <Form.Control
                            as="select"
                            type="text"
                            name={`levelStart_${value.id}`}
                            required
                            value={profileLevel.subjects && profileLevel.subjects[value.id] && profileLevel.subjects[value.id].levelStart}
                            onChange={onChangeHandler}
                          >
                            {Object.values(fieldLevels).map((value2) => <option key={value2.id}
                                                                                value={value2.id}>{value2.name}</option>)}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Level End</Form.Label>
                          <Form.Control
                            as="select"
                            type="text"
                            name={`levelEnd_${value.id}`}
                            required
                            value={profileLevel.subjects && profileLevel.subjects[value.id] && profileLevel.subjects[value.id].levelEnd}
                            onChange={onChangeHandler}
                          >
                            {Object.values(fieldLevels).map((value2) => <option key={value2.id}
                                                                                value={value2.id}>{value2.name}</option>)}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form.Group> :
                  <Form.Group className='ml-3'>
                    <Form.Label>Subject proficiency</Form.Label>
                    {Object.entries(proficiencyOptions).map(([key, value2]) => (
                      <Form.Check
                        key={key}
                        label={value2}
                        checked={key === (profileLevel.subjects && profileLevel.subjects[value.id])}
                        type='radio'
                        name={`proficiency_${value.id}`}
                        id={`${value.id}_proficiency_${value2}`}
                        value={key}
                        onChange={onChangeHandler}
                      />
                    ))}
                  </Form.Group>
                }

              </Form.Group>
            )}

          </Form.Group>
          <Form.Group>
            <Form.Label>Fee</Form.Label>
            <Form.Control
              type="number"
              name="fee"
              value={profileLevel.fee}
              onChange={onChangeHandler}
            />
          </Form.Group>
          {isTutor ?
            <Form.Group>
              <Form.Group>
                <Form.Label>Teaching Since</Form.Label>
                <Form.Control
                  type="number"
                  name="teachingSince"
                  length={4}
                  value={profileLevel.teachingSince}
                  onChange={onChangeHandler}
                />
              </Form.Group>
              <Form.Group>
                <Form.Check
                  checked={profileLevel.isMoe} name="isMoe"
                  label="Is Moe" type='checkbox'
                  onChange={onChangeHandler}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Hours per week to teach</Form.Label>
                <Form.Control
                  type="text"
                  name="tutorMinDuration"
                  value={profileLevel.tutorMinDuration}
                  onChange={onChangeHandler}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Teach in Student&apos;s Home</Form.Label>
                {Object.entries(booleanOptions).map(([key, value]) => <Form.Check
                  key={key}
                  label={value}
                  type='radio'
                  name="tutorTechInHome"
                  id={`teachInHome_${value}`}
                  value={key}
                  checked={key == profileLevel.tutorTechInHome}
                  onChange={onChangeHandler}
                />)}

              </Form.Group>

              <Form.Group>
                <Form.Label>Youtube Url</Form.Label>
                <Form.Control
                  type="url"
                  name="tutorVideoUrl"
                  value={profileLevel.tutorVideoUrl}
                  onChange={onChangeHandler}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>CV</Form.Label>
                <div className="file has-name">
                  <label className="file-label">
                    <input
                      className="file-input"
                      type="file"
                      accept="image/*,.doc,.docx,.pdf"
                      onChange={onFileChangedHandler}
                    />
                    {userData.cvUrl && <a href={userData.cvUrl} target='_blank'>View Profile CV</a>}
                  </label>
                </div>
              </Form.Group>

            </Form.Group>
            :
            <Form.Group>
              <Form.Group>
                <Form.Check
                  checked={profileLevel.isParent} name="isParent"
                  label="Is Parent" type='checkbox'
                  onChange={onChangeHandler}
                />
              </Form.Group>
              <Form.Group className={!profileLevel.isParent && `d-none`}>

                <Form.Group>
                  <Form.Label>Student&apos;s Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="studentName"
                    value={profileLevel.studentName}
                    onChange={onChangeHandler}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Student&apos;s Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    name="studentMobile"
                    value={profileLevel.studentMobile}
                    onChange={onChangeHandler}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Student&apos;s Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="studentEmail"
                    value={profileLevel.studentEmail}
                    onChange={onChangeHandler}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Student&apos;s DOB</Form.Label>
                  <div className="mb-2">
                    <DatePicker
                      name="studentDob"
                      date={new Date(profileLevel.studentDob || new Date().toDateString())}
                      setState={setProfileLevel}
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Student&apos;s Gender</Form.Label>
                  {Object.values(genderOptions).map(value => <Form.Check
                    key={value}
                    label={value}
                    type='radio'
                    name='studentGender'
                    value={value.toLowerCase()}
                    checked={profileLevel.studentGender === value.toLowerCase()}
                    onChange={onChangeHandler}
                    id={`gender_${value}`}
                  />)}


                </Form.Group>

              </Form.Group>
            </Form.Group>
          }


          <hr/>

          <div className="form-group">

            <button
              type="submit"
              className={`btn btn-primary ${loading &&
              'is-loading'}`}
            >
              <span>{useFormatMessage('UserForm.submit')}</span>
            </button>
            <Link to={paths.MANAGE_STUDENT} className="button">
              {goBackMessage}
            </Link>
          </div>
        </Col>
        <Col lg={6} md={12}>
          <Form.Group>
            <Form.Label>
              Preferred Time
            </Form.Label>
            <Form.Control
              as="select"
              type="text"
              name="preferredTime"
              required
              value={profileLevel.preferredTime}
              onChange={onChangeHandler}
            >
              {Object.entries(preferredTime).map(([key, value]) => <option key={key}
                                                                 value={key}>{value}</option>)}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Start Date</Form.Label>
            <div className="mb-2">
              <DatePicker
                name="startDate"
                date={new Date(profileLevel.startDate || new Date().toDateString())}
                setState={setProfileLevel}
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Group Tuition</Form.Label>
            {Object.entries(booleanOptions).map(([key, value]) => <Form.Check
              key={key}
              label={value}
              type='radio'
              name="groupTuition"
              value={key}
              checked={key === profileLevel.groupTuition}
              onChange={onChangeHandler}
              id={`groupTuition_${value}`}
            />)}

          </Form.Group>

          <Form.Group>
            <Form.Label>{isTutor ? 'Brief Introduction' : 'Additional Request'}</Form.Label>
            <Form.Control as='textarea' name="additionalRequest" onChange={onChangeHandler}
                          value={profileLevel.additionalRequest}/>
          </Form.Group>

          <Form.Group>
            <Form.Label>Browsing Level </Form.Label>
            <Form.Control
              as="select"
              type="text"
              name="browsingLevelId"
              required
              value={profileLevel.browsingLevelId}
              onChange={onChangeHandler}
            >
              {Object.values(levels).map((value) => <option key={value.id}
                                                            value={value.id}>{value.name}</option>)}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
    </form>
  );
};

export default ProfileLevelForm;
