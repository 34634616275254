import React from 'react';
import { Row, Col, Card, Table, Tabs, Tab } from 'react-bootstrap';


import avatar1 from '../../assets/images/user/avatar-1.jpg';
import avatar2 from '../../assets/images/user/avatar-2.jpg';
import avatar3 from '../../assets/images/user/avatar-3.jpg';

const Home = () => {
  const tabContent = (
    <>
      <div className="media friendlist-box align-items-center justify-content-center m-b-20">
        <div className="m-r-10 photo-table">
          <a href='#'><img className="rounded-circle" style={{ width: '40px' }} src={avatar1} alt="activity-user"/></a>
        </div>
        <div className="media-body">
          <h6 className="m-0 d-inline">Silje Larsen</h6>
          <span className="float-right d-flex  align-items-center"><i
            className="fa fa-caret-up f-22 m-r-10 text-c-green"/>3784</span>
        </div>
      </div>
      <div className="media friendlist-box align-items-center justify-content-center m-b-20">
        <div className="m-r-10 photo-table">
          <a href='#'><img className="rounded-circle" style={{ width: '40px' }} src={avatar2} alt="activity-user"/></a>
        </div>
        <div className="media-body">
          <h6 className="m-0 d-inline">Julie Vad</h6>
          <span className="float-right d-flex  align-items-center"><i
            className="fa fa-caret-up f-22 m-r-10 text-c-green"/>3544</span>
        </div>
      </div>
      <div className="media friendlist-box align-items-center justify-content-center m-b-20">
        <div className="m-r-10 photo-table">
          <a href='#'><img className="rounded-circle" style={{ width: '40px' }} src={avatar3} alt="activity-user"/></a>
        </div>
        <div className="media-body">
          <h6 className="m-0 d-inline">Storm Hanse</h6>
          <span className="float-right d-flex  align-items-center"><i
            className="fa fa-caret-down f-22 m-r-10 text-c-red"/>2739</span>
        </div>
      </div>
      <div className="media friendlist-box align-items-center justify-content-center m-b-20">
        <div className="m-r-10 photo-table">
          <a href='#'><img className="rounded-circle" style={{ width: '40px' }} src={avatar1} alt="activity-user"/></a>
        </div>
        <div className="media-body">
          <h6 className="m-0 d-inline">Frida Thomse</h6>
          <span className="float-right d-flex  align-items-center"><i
            className="fa fa-caret-down f-22 m-r-10 text-c-red"/>1032</span>
        </div>
      </div>
      <div className="media friendlist-box align-items-center justify-content-center m-b-20">
        <div className="m-r-10 photo-table">
          <a href='#'><img className="rounded-circle" style={{ width: '40px' }} src={avatar2} alt="activity-user"/></a>
        </div>
        <div className="media-body">
          <h6 className="m-0 d-inline">Silje Larsen</h6>
          <span className="float-right d-flex  align-items-center"><i
            className="fa fa-caret-up f-22 m-r-10 text-c-green"/>8750</span>
        </div>
      </div>
      <div className="media friendlist-box align-items-center justify-content-center">
        <div className="m-r-10 photo-table">
          <a href='#'><img className="rounded-circle" style={{ width: '40px' }} src={avatar3} alt="activity-user"/></a>
        </div>
        <div className="media-body">
          <h6 className="m-0 d-inline">Storm Hanse</h6>
          <span className="float-right d-flex  align-items-center"><i
            className="fa fa-caret-down f-22 m-r-10 text-c-red"/>8750</span>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Row>
        <Col md={6} xl={12}>
          <Card className='Recent-Users'>
            <Card.Header>
              <Card.Title as='h5'>Recent Users</Card.Title>
            </Card.Header>
            <Card.Body className='px-0 py-2'>
              <Table responsive hover>
                <tbody>
                <tr className="unread">
                  <td><img className="rounded-circle" style={{ width: '40px' }} src={avatar1} alt="activity-user"/></td>
                  <td>
                    <h6 className="mb-1">Isabella Christensen</h6>
                    <p className="m-0">Lorem Ipsum is simply dummy text of…</p>
                  </td>
                  <td>
                    <h6 className="text-muted"><i className="fa fa-circle text-c-green f-10 m-r-15"/>11 MAY 12:56</h6>
                  </td>
                  <td><a href='#' className="label theme-bg2 text-white f-12">Reject</a><a href='#'
                                                                                           className="label theme-bg text-white f-12">Approve</a>
                  </td>
                </tr>
                <tr className="unread">
                  <td><img className="rounded-circle" style={{ width: '40px' }} src={avatar2} alt="activity-user"/></td>
                  <td>
                    <h6 className="mb-1">Mathilde Andersen</h6>
                    <p className="m-0">Lorem Ipsum is simply dummy text of…</p>
                  </td>
                  <td>
                    <h6 className="text-muted"><i className="fa fa-circle text-c-red f-10 m-r-15"/>11 MAY 10:35</h6>
                  </td>
                  <td><a href='#' className="label theme-bg2 text-white f-12">Reject</a><a href='#'
                                                                                           className="label theme-bg text-white f-12">Approve</a>
                  </td>
                </tr>
                <tr className="unread">
                  <td><img className="rounded-circle" style={{ width: '40px' }} src={avatar3} alt="activity-user"/></td>
                  <td>
                    <h6 className="mb-1">Karla Sorensen</h6>
                    <p className="m-0">Lorem Ipsum is simply dummy text of…</p>
                  </td>
                  <td>
                    <h6 className="text-muted"><i className="fa fa-circle text-c-green f-10 m-r-15"/>9 MAY 17:38</h6>
                  </td>
                  <td><a href='#' className="label theme-bg2 text-white f-12">Reject</a><a href='#'
                                                                                           className="label theme-bg text-white f-12">Approve</a>
                  </td>
                </tr>
                <tr className="unread">
                  <td><img className="rounded-circle" style={{ width: '40px' }} src={avatar1} alt="activity-user"/></td>
                  <td>
                    <h6 className="mb-1">Ida Jorgensen</h6>
                    <p className="m-0">Lorem Ipsum is simply dummy text of…</p>
                  </td>
                  <td>
                    <h6 className="text-muted f-w-300"><i className="fa fa-circle text-c-red f-10 m-r-15"/>19 MAY 12:56
                    </h6>
                  </td>
                  <td><a href='#' className="label theme-bg2 text-white f-12">Reject</a><a href='#'
                                                                                           className="label theme-bg text-white f-12">Approve</a>
                  </td>
                </tr>
                <tr className="unread">
                  <td><img className="rounded-circle" style={{ width: '40px' }} src={avatar2} alt="activity-user"/></td>
                  <td>
                    <h6 className="mb-1">Albert Andersen</h6>
                    <p className="m-0">Lorem Ipsum is simply dummy text of…</p>
                  </td>
                  <td>
                    <h6 className="text-muted"><i className="fa fa-circle text-c-green f-10 m-r-15"/>21 July 12:56</h6>
                  </td>
                  <td><a href='#' className="label theme-bg2 text-white f-12">Reject</a><a href='#'
                                                                                           className="label theme-bg text-white f-12">Approve</a>
                  </td>
                </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} xl={12} className='m-b-30'>
          <Tabs defaultActiveKey="today" id="uncontrolled-tab-example">
            <Tab eventKey="today" title="Today">
              {tabContent}
            </Tab>
            <Tab eventKey="week" title="This Week">
              {tabContent}
            </Tab>
            <Tab eventKey="all" title="All">
              {tabContent}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default Home;