import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';

import { useChangeHandler, useFormatMessage } from 'hooks';
import { resetPassword, authCleanUp } from 'state/actions/auth';
import paths from 'pages/Router/paths';
import classes from '../Login/Login.scss';

const ResetPassword = () => {
  const { loading, error, restoredPassword, isAuth } = useSelector(
    state => ({
      loading: state.auth.loading,
      error: state.auth.error,
      restoredPassword: state.auth.restoredPassword,
      isAuth: !!state.auth.userData.userId
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [resetPasswordData, setResetPasswordData] = useState('');

  const onChangeHandler = useChangeHandler(setResetPasswordData);

  useEffect(() => {
    document.documentElement.classList.remove(
      'has-aside-left',
      'has-navbar-fixed-top'
    );
    return () => {
      document.documentElement.classList.add(
        'has-aside-left',
        'has-navbar-fixed-top'
      );
      dispatch(authCleanUp());
    };
  }, [dispatch]);

  const onSubmitHandler = event => {
    event.preventDefault();
    dispatch(resetPassword(resetPasswordData.email));
  };

  const modifierLoading = loading && 'is-loading';

  const redirect = isAuth && <Redirect to={paths.ROOT} />;

  const recoverEmailMessage = useFormatMessage('ResetPassword.recoverEmail', {
    mail: resetPasswordData.email
  });
  const emailMessage = useFormatMessage('ResetPassword.email');
  const emailRegistrationMessage = useFormatMessage(
    'ResetPassword.emailRegistration'
  );
  const resetLinkMessage = useFormatMessage('ResetPassword.resetLink');
  const backMessage = useFormatMessage('ResetPassword.back');

  return (
    <section className="section">
      {redirect}
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          <div className="card text-center">
            <header className="card-header">
              <p className="card-header-title">
                <div className="mb-4">
                  <i className="feather icon-unlock auth-icon" />
                </div>
                <h3>{useFormatMessage('ResetPassword.recovery')}</h3>
              </p>
            </header>
            <div className="card-body">
              {restoredPassword ? (
                <p className={classes['sub-title']}>
                  {recoverEmailMessage}
                </p>
              ) : (
                <form onSubmit={onSubmitHandler}>
                  <div className="input-group mb-3">
                    <input
                      type="email"
                      className="form-control"
                      required
                      value={resetPasswordData.email}
                      name="email"
                      onChange={onChangeHandler}
                      placeholder={emailRegistrationMessage}
                    />
                  </div>
                  <div className="field is-grouped">
                    <div className="control">
                      <button
                        className={`btn btn-primary ${modifierLoading}`}
                        type="submit"
                      >
                        {resetLinkMessage}
                      </button>
                    </div>
                    <div className="control">
                      <Link to={paths.LOGIN} className="button is-outlined">
                        {backMessage}
                      </Link>
                    </div>
                  </div>
                  {error && (
                    <p
                      className={`has-text-danger ${classes.errorMessage}`}
                    >
                      {error}
                    </p>
                  )}
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
