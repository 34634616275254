import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import firebase from 'firebase.js';

export const COUNTRY_FETCH_DATA_INIT = createAction('COUNTRY_FETCH_DATA_INIT');
export const COUNTRY_FETCH_DATA_SUCCESS = createAction(
  'COUNTRY_FETCH_DATA_SUCCESS'
);
export const COUNTRY_FETCH_DATA_FAIL = createAction('COUNTRY_FETCH_DATA_FAIL');

export const COUNTRY_TOGGLE_OBSOLETE_COUNTRY_INIT = createAction('COUNTRY_TOGGLE_OBSOLETE_COUNTRY_INIT');
export const COUNTRY_UNSET_COUNTRY_OBSOLETE_SUCCESS = createAction(
  'COUNTRY_UNSET_COUNTRY_OBSOLETE_SUCCESS'
);
export const COUNTRY_UNSET_COUNTRY_OBSOLETE_FAIL = createAction('COUNTRY_UNSET_COUNTRY_OBSOLETE_FAIL');

export const COUNTRY_CLEAR_DATA = createAction('COUNTRY_CLEAR_DATA');

export const COUNTRY_CREATE_COUNTRY_INIT = createAction('COUNTRY_CREATE_COUNTRY_INIT');
export const COUNTRY_SET_COUNTRY_OBSOLETE_SUCCESS = createAction(
  'COUNTRY_SET_COUNTRY_OBSOLETE_SUCCESS'
);
export const COUNTRY_SET_COUNTRY_OBSOLETE_FAIL = createAction('COUNTRY_SET_COUNTRY_OBSOLETE_FAIL');

export const COUNTRY_MODIFY_COUNTRY_INIT = createAction('COUNTRY_MODIFY_COUNTRY_INIT');

export const COUNTRY_CLEAN_UP = createAction('COUNTRY_CLEAN_UP');

export const COUNTRY_CLEAR_DATA_LOGOUT = createAction('COUNTRY_CLEAR_DATA_LOGOUT');

const countriesJson = require('../../assets/CountryCodes');


export const fetchCountries = (all = false) => {
  return async (dispatch) => {

    dispatch(COUNTRY_FETCH_DATA_INIT());

    let countries;

    try {
      countries = (
        await firebase
          .database()
          .ref('countries')
          .once('value')
      ).val();
    } catch (error) {
      toastr.error('', error);
      return dispatch(COUNTRY_FETCH_DATA_FAIL({ error }));
    }
    const countriesFB = countries
      ? Object.entries(countries).map(([key, value]) => ({
        id: key,
        ...value
      }))
      : [];
    let countriesData = [];
    if (all) {
      countriesData = countriesJson.map((country) => (
        countriesFB.find(item => item.code === country.code) || country
      ));
    } else {
      countriesData = countriesJson.filter((country) => !countriesFB.find(item => item.code === country.code) );
    }

    return dispatch(
      COUNTRY_FETCH_DATA_SUCCESS({ countries: countriesData })
    );
  };
};

export const unSetCountryObsolete = country => {
  return async (dispatch, getState) => {
    dispatch(COUNTRY_TOGGLE_OBSOLETE_COUNTRY_INIT());
    const { locale } = getState().preferences;

    const deleteCountryTask = firebase
      .database()
      .ref(`countries/${country.id}`)
      .remove();

    try {
      await Promise.all([deleteCountryTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        COUNTRY_UNSET_COUNTRY_OBSOLETE_FAIL({
          error: errorMessage
        })
      );
    }
    toastr.success('', 'The country was updated successfully.');
    return dispatch(COUNTRY_UNSET_COUNTRY_OBSOLETE_SUCCESS({ ...country }));
  };
};

export const clearCountrysData = () => {
  return dispatch => {
    dispatch(COUNTRY_CLEAR_DATA());
  };
};

export const clearCountrysDataLogout = () => {
  return dispatch => {
    dispatch(COUNTRY_CLEAR_DATA_LOGOUT());
  };
};

export const setCountryObsolete = (country ) => {
  return async (dispatch, getState) => {
    dispatch(COUNTRY_TOGGLE_OBSOLETE_COUNTRY_INIT());
    const { locale } = getState().preferences;

    const uid = firebase.database().ref().child('countries').push().key;

    const createCountryDbTask = firebase
      .database()
      .ref(`countries/${uid}`)
      .set(country);

    try {
      await Promise.all([
        createCountryDbTask
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        COUNTRY_SET_COUNTRY_OBSOLETE_FAIL({
          error: errorMessage
        })
      );
    }
    toastr.success('', 'The country was updated successfully.');
    return dispatch(COUNTRY_SET_COUNTRY_OBSOLETE_SUCCESS({ ...country, id: uid }));
  };
};