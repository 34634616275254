import React from 'react';
import Link from '../../Link';

const navLogo = ({ collapseMenu, onToggleNavigation }) => {
    let toggleClass = ['mobile-menu'];
    if (collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }

    return (
        <>
            <div className="navbar-brand header-logo">
                 <Link to='/' className="b-brand">
                    <div className="b-bg">
                        <i className="feather icon-trending-up" />
                    </div>
                    <span className="b-title">Tuition Admin</span>
                 </Link>
                <a href='#' className={toggleClass.join(' ')} id="mobile-collapse" onClick={onToggleNavigation}><span /></a>
            </div>
        </>
    );
};

export default navLogo;
