import { createAction } from 'redux-act';

export const PREFERENCES_SET_LOCALE = createAction('PREFERENCES_SET_LOCALE');

export const COLLAPSE_MENU = createAction('COLLAPSE_MENU');
export const COLLAPSE_TOGGLE = createAction('COLLAPSE_TOGGLE');
export const CHANGE_LAYOUT = createAction('CHANGE_LAYOUT');
export const NAV_CONTENT_LEAVE = createAction('NAV_CONTENT_LEAVE');
export const NAV_COLLAPSE_LEAVE = createAction('NAV_COLLAPSE_LEAVE');

export const setUserLocale = locale => dispatch => {
  return dispatch(PREFERENCES_SET_LOCALE({ locale }));
};

export const collapseMenuAction = () => dispatch => {
  return dispatch(COLLAPSE_MENU());
};

export const collapseToggle = (payload) => dispatch => {
  return dispatch(COLLAPSE_TOGGLE(payload));
};

export const changeLayout = (payload) => dispatch => {
  return dispatch(CHANGE_LAYOUT(payload));
};

export const navContentLeave = () => dispatch => {
  return dispatch(NAV_CONTENT_LEAVE());
};

export const navCollapseLeave = (payload) => dispatch => {
  return dispatch(NAV_COLLAPSE_LEAVE(payload));
};