import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import UserForm from 'components/UserForm';
import { createUser, modifyUser } from 'state/actions/users';
import paths from 'pages/Router/paths';
import firebase from 'firebase.js';
import { useFormatMessage } from 'hooks';
import { Card } from 'react-bootstrap';

const User = () => {
  const { id } = useParams();

  const { success, isAdmin } = useSelector(
    state => ({
      success: state.users.success,
      isAdmin: state.auth.userData.isAdmin
    }),
    shallowEqual
  );

  const [user, setUser] = useState({
    name: '',
    email: '',
    location: '',
    isAdmin: false,
    file: null,
    createdAt: new Date().toUTCString()
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const response = (
        await firebase
          .database()
          .ref(`users/${id}`)
          .once('value')
      ).val();
      return response;
    };

    if (id) {
      fetchUserData()
        .then(userData => {
          setUser({
            ...userData,
            createdAt: userData.createdAt,
            id,
            isAdmin: userData.isAdmin
          });
        })
        .catch(() => {
          setUser({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const userForm =
    !user.name && id ? (
      <ClipLoader />
    ) : (
      <UserForm
        isEditing={isEditing}
        userData={user}
        action={isEditing ? modifyUser : createUser}
      />
    );

  const redirectAdmin = !isAdmin && <Redirect to={paths.ROOT}/>;
  const redirect = (user.error || success) && <Redirect to={paths.USERS} />;

  const editUserMessage = useFormatMessage('User.editUser');

  const newUserMessage = useFormatMessage('User.newUser');

  return (
    <>
      {redirectAdmin}
      {redirect}
      <Card>
        <Card.Header>
          <Card.Title as="h5">{isEditing ? editUserMessage : newUserMessage}</Card.Title>
        </Card.Header>
        <Card.Body>{userForm}</Card.Body>
      </Card>

    </>
  );
};

export default User;
