import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';


import firebase from '../../firebase';
import { firebaseError } from '../../utils';

export const ORDER_FETCH_DATA_INIT = createAction('ORDER_FETCH_DATA_INIT');
export const ORDER_FETCH_DATA_SUCCESS = createAction('ORDER_FETCH_DATA_SUCCESS');
export const ORDER_FETCH_DATA_FAIL = createAction('ORDER_FETCH_DATA_FAIL');

export const ORDER_CREATE_ORDER_INIT = createAction('ORDER_CREATE_ORDER_INIT');
export const ORDER_CREATE_ORDER_FAIL = createAction('ORDER_CREATE_ORDER_FAIL');
export const ORDER_CREATE_ORDER_SUCCESS = createAction('ORDER_CREATE_ORDER_SUCCESS');

export const ORDER_MODIFY_ORDER_INIT = createAction('ORDER_MODIFY_ORDER_INIT');
export const ORDER_MODIFY_ORDER_FAIL = createAction('ORDER_MODIFY_ORDER_FAIL');
export const ORDER_MODIFY_ORDER_SUCCESS = createAction('ORDER_MODIFY_ORDER_SUCCESS');

export const ORDER_CLEAR_DATA = createAction('ORDER_CLEAR_DATA');

export const ORDER_CLEAN_UP = createAction('ORDER_CLEAN_UP');

export const ORDER_CLEAR_DATA_LOGOUT = createAction('ORDER_CLEAR_DATA_LOGOUT');

export const fetchOrders = ( all = false) => {
  return async (dispatch) => {

    dispatch(ORDER_FETCH_DATA_INIT());

    let subjects;

    try {
      subjects = (
        await firebase
          .database()
          .ref('orders')
          .orderByChild('order')
          .once('value')
      ).val();
    } catch (error) {
      return dispatch(ORDER_FETCH_DATA_FAIL({ error }));
    }
    const subjectsData = subjects
      ? Object.values(subjects)
      : [];
    const result = all ? subjectsData : subjectsData.filter(item => !item.isObsolete);
    return dispatch(
      ORDER_FETCH_DATA_SUCCESS({ subjects: result })
    );
  };
};

export const createOrder = (data) => {
  return async (dispatch) => {
    dispatch(ORDER_CREATE_ORDER_INIT());

    const uid = firebase.database().ref().child('orders').push().key;

    const order = {...data,
      id: uid
    };

    const createSubjectDbTask = firebase
      .database()
      .ref(`orders/${uid}`)
      .set(order);

    try {
      await Promise.all([
        createSubjectDbTask
      ]);
    } catch (error) {
      return dispatch(
        ORDER_CREATE_ORDER_FAIL({
          error: error.message
        })
      );
    }
    dispatch(ORDER_CREATE_ORDER_SUCCESS(order));
  };
};

export const modifyOrder = (order) => {
  return async (dispatch, getState) => {
    dispatch(ORDER_MODIFY_ORDER_INIT());
    const { locale } = getState().preferences;

    const updateSubjectDbTask = firebase
      .database()
      .ref(`orders/${order.id}`)
      .update(order);

    try {
      await Promise.all([updateSubjectDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        ORDER_MODIFY_ORDER_FAIL({
          error: errorMessage
        })
      );
    }
    toastr.success('', 'Order approved successfully');

    return dispatch(ORDER_MODIFY_ORDER_SUCCESS(order));
  };
};

export const clearOrdersData = () => {
  return dispatch => {
    dispatch(ORDER_CLEAR_DATA());
  };
};

export const clearOrdersDataLogout = () => {
  return dispatch => {
    dispatch(ORDER_CLEAR_DATA_LOGOUT());
  };
};

export const subjectsCleanUp = () => dispatch => dispatch(ORDER_CLEAN_UP());
