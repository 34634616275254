import React from 'react';

import './Footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              © 2020 <span>&nbsp; Tuition Admin</span>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <div className="logo">
                Logo
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;