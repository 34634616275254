import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import { createUser, modifyUser } from 'state/actions/tutors';
import paths from 'pages/Router/paths';
import firebase from 'firebase.js';
import { Card, Tab, Tabs } from 'react-bootstrap';
import BasicForm from '../../components/BasicForm';
import ProfileLevelForm from '../../components/ProfileLevelForm';
import TestResult from '../../components/TestResult';

const Tutor = () => {
  const { id } = useParams();

  const { success, isAdmin } = useSelector(
    state => ({
      success: state.tutors.success,
      isAdmin: state.auth.userData.isAdmin
    }),
    shallowEqual
  );

  const [user, setUser] = useState({
    dob: new Date().toDateString(),
    gender: 'male',
    createdAt: new Date().toUTCString(),
    status: 'active',
    countryId: 'SG',
    profileLevel: {
      subjects: [],
      startDate: new Date().toDateString(),
      groupTuition: 0,
    }
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const response = (
        await firebase
          .database()
          .ref(`tutors/${id}`)
          .once('value')
      ).val();
      return response;
    };
    if (id) {
      fetchUserData()
        .then(userData => {
          setUser(userData);
        })
        .catch(() => {
          setUser({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;
  const userForm =
    !user.email && id ? (
      <ClipLoader/>
    ) : (
      <BasicForm
        isEditing={isEditing}
        userData={user}
        isTutor
        action={isEditing ? modifyUser : createUser}
      />
    );

  const profileForm =
    !user.email && id ? (
      <ClipLoader/>
    ) : (
      <ProfileLevelForm
        userData={user}
        isTutor
        action={modifyUser}
      />
    );
  const redirectAdmin = !isAdmin && <Redirect to={paths.ROOT}/>;

  return (
    <>
      {redirectAdmin}
      <Card>
        <Card.Header>
          <Card.Title as="h5">{isEditing ? 'Edit Tutor' : 'New Tutor'}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Tabs defaultActiveKey="basic" id="uncontrolled-tab-example">
            <Tab eventKey="basic" title="Basic Profile">
              {userForm}
            </Tab>
            <Tab eventKey="profile" title="Profile Level" disabled={!id}>
              {profileForm}
            </Tab>
            <Tab eventKey="test" title="Test result" disabled={!id}>
              <TestResult userData={user} action={modifyUser} />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>

    </>
  );
};

export default Tutor;
