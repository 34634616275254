import React from 'react';
import { Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import qrcode from '../../assets/images/qrcode.jpg';

const QRCode = require('qrcode.react');

const paynow = require('paynow-generator').paynowGenerator;

const PaymentInstruction = () => {
  const { id, total } = useParams();

  // const merchantName = 'FUTURE STEPS PTE LTD';
  // const proxyValue = '202017151K';
  // let uen;
  // // if (id) {
  // //   uen = paynow('UEN', proxyValue, false, total, merchantName, id);
  // // }

  return (
    <div style={{ marginTop: '20px', marginLeft: '10px', marginRight: '10px' }}>
      <p>Please open your banking and select PayNow to scan the following QR code:</p>
      <img style={{ width: '256px' }} src={qrcode} alt='QR code' />
      <p>If you do not have PayNow, you may use internet banking to transfer to:</p>
      <br/>
      <p>Bank Account Name : Future Steps Pte Ltd</p>
      <p>UEN no : 202017151K</p>
      <p>Bank Name : Maybank Singapore Ltd</p>
      <p>Bank Code : 7302</p>
      <p>Account Number : 04011134897</p>
      <br/>
      <p>Please allow up to 2 working days for us to complete and accept your order.</p>
      <p>You may contact us at email <b>enquiry@futuresteps.com.sg</b> if you have questions.</p>
      <br/>
      <p>Thank you.</p>
    </div>
  );
};

export default PaymentInstruction;
