import { createReducer } from 'redux-act';

import {
  FIELD_LEVELS_FETCH_DATA_INIT,
  FIELD_LEVELS_FETCH_DATA_SUCCESS,
  FIELD_LEVELS_FETCH_DATA_FAIL,
  FIELD_LEVELS_DELETE_LEVEL_INIT,
  FIELD_LEVELS_DELETE_LEVEL_SUCCESS,
  FIELD_LEVELS_DELETE_LEVEL_FAIL,
  FIELD_LEVELS_CLEAR_DATA,
  FIELD_LEVELS_CREATE_LEVEL_INIT,
  FIELD_LEVELS_CREATE_LEVEL_SUCCESS,
  FIELD_LEVELS_CREATE_LEVEL_FAIL,
  FIELD_LEVELS_MODIFY_LEVEL_INIT,
  FIELD_LEVELS_MODIFY_LEVEL_SUCCESS,
  FIELD_LEVELS_MODIFY_LEVEL_FAIL,
  FIELD_LEVELS_CLEAN_UP,
  FIELD_LEVELS_CLEAR_DATA_LOGOUT
} from '../../actions/fieldLevels';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false
};

export const fieldLevelsReducer = createReducer(
  {
    [FIELD_LEVELS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true
    }),
    [FIELD_LEVELS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.levels,
      loading: false,
      error: null
    }),
    [FIELD_LEVELS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [FIELD_LEVELS_DELETE_LEVEL_INIT]: state => ({
      ...state,
      loading: true
    }),
    [FIELD_LEVELS_DELETE_LEVEL_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter(elem => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true
    }),
    [FIELD_LEVELS_DELETE_LEVEL_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [FIELD_LEVELS_CLEAR_DATA]: state => ({
      ...initialState,
      data: state.data
    }),
    [FIELD_LEVELS_CREATE_LEVEL_INIT]: state => ({
      ...state,
      loading: true
    }),
    [FIELD_LEVELS_CREATE_LEVEL_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload),
      loading: false,
      error: null,
      success: true
    }),
    [FIELD_LEVELS_CREATE_LEVEL_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [FIELD_LEVELS_MODIFY_LEVEL_INIT]: state => ({
      ...state,
      loading: true
    }),
    [FIELD_LEVELS_MODIFY_LEVEL_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.map(elem => {
        if (elem.id === payload.id) {
          return payload;
        }
        return elem;
      }),
      loading: false,
      error: null,
      success: true
    }),
    [FIELD_LEVELS_MODIFY_LEVEL_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [FIELD_LEVELS_CLEAN_UP]: state => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false
    }),
    [FIELD_LEVELS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState
    })
  },
  initialState
);
