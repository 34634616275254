import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import firebase from 'firebase.js';

export const SUBJECT_FETCH_DATA_INIT = createAction('SUBJECT_FETCH_DATA_INIT');
export const SUBJECT_FETCH_DATA_SUCCESS = createAction(
  'SUBJECT_FETCH_DATA_SUCCESS'
);
export const SUBJECT_FETCH_DATA_FAIL = createAction('SUBJECT_FETCH_DATA_FAIL');

export const SUBJECT_DELETE_SUBJECT_INIT = createAction('SUBJECT_DELETE_SUBJECT_INIT');
export const SUBJECT_DELETE_SUBJECT_SUCCESS = createAction(
  'SUBJECT_DELETE_SUBJECT_SUCCESS'
);
export const SUBJECT_DELETE_SUBJECT_FAIL = createAction('SUBJECT_DELETE_SUBJECT_FAIL');

export const SUBJECT_CLEAR_DATA = createAction('SUBJECT_CLEAR_DATA');

export const SUBJECT_CREATE_SUBJECT_INIT = createAction('SUBJECT_CREATE_SUBJECT_INIT');
export const SUBJECT_CREATE_SUBJECT_SUCCESS = createAction(
  'SUBJECT_CREATE_SUBJECT_SUCCESS'
);
export const SUBJECT_CREATE_SUBJECT_FAIL = createAction('SUBJECT_CREATE_SUBJECT_FAIL');

export const SUBJECT_MODIFY_SUBJECT_INIT = createAction('SUBJECT_MODIFY_SUBJECT_INIT');
export const SUBJECT_MODIFY_SUBJECT_SUCCESS = createAction(
  'SUBJECT_MODIFY_SUBJECT_SUCCESS'
);
export const SUBJECT_MODIFY_SUBJECT_FAIL = createAction('SUBJECT_MODIFY_SUBJECT_FAIL');

export const SUBJECT_CLEAN_UP = createAction('SUBJECT_CLEAN_UP');

export const SUBJECT_CLEAR_DATA_LOGOUT = createAction('SUBJECT_CLEAR_DATA_LOGOUT');

export const fetchSubjects = ( all = false) => {
  return async (dispatch) => {

    dispatch(SUBJECT_FETCH_DATA_INIT());

    let subjects;

    try {
      subjects = (
        await firebase
          .database()
          .ref('subjects')
          .orderByChild('order')
          .once('value')
      ).val();
    } catch (error) {
      toastr.error('', error);
      return dispatch(SUBJECT_FETCH_DATA_FAIL({ error }));
    }
    const subjectsData = subjects
      ? Object.values(subjects)
      : [];
    const result = all ? subjectsData : subjectsData.filter(item => !item.isObsolete)
    return dispatch(
      SUBJECT_FETCH_DATA_SUCCESS({ subjects: result })
    );
  };
};

export const deleteSubject = id => {
  return async (dispatch, getState) => {
    dispatch(SUBJECT_DELETE_SUBJECT_INIT());
    const { locale } = getState().preferences;

    const deleteSubjectTask = firebase
      .database()
      .ref(`subjects/${id}`)
      .remove();

    try {
      await Promise.all([deleteSubjectTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SUBJECT_DELETE_SUBJECT_FAIL({
          error: errorMessage
        })
      );
    }

    toastr.success('', 'The subject was deleted.');
    return dispatch(SUBJECT_DELETE_SUBJECT_SUCCESS({ id }));
  };
};

export const clearSubjectsData = () => {
  return dispatch => {
    dispatch(SUBJECT_CLEAR_DATA());
  };
};

export const clearSubjectsDataLogout = () => {
  return dispatch => {
    dispatch(SUBJECT_CLEAR_DATA_LOGOUT());
  };
};

export const createSubject = (data ) => {
  return async (dispatch, getState) => {
    dispatch(SUBJECT_CREATE_SUBJECT_INIT());
    const { locale } = getState().preferences;

    const uid = firebase.database().ref().child('subjects').push().key;

    const subject = {...data, id: uid};

    const createSubjectDbTask = firebase
      .database()
      .ref(`subjects/${uid}`)
      .set(subject);

    try {
      await Promise.all([
        createSubjectDbTask
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SUBJECT_CREATE_SUBJECT_FAIL({
          error: errorMessage
        })
      );
    }
    toastr.success('', 'Subject created successfully');
    return dispatch(SUBJECT_CREATE_SUBJECT_SUCCESS(subject));
  };
};

export const modifySubject = (subjectData) => {
  return async (dispatch, getState) => {
    dispatch(SUBJECT_MODIFY_SUBJECT_INIT());
    const { locale } = getState().preferences;

    const updateSubjectDbTask = firebase
      .database()
      .ref(`subjects/${subjectData.id}`)
      .update(subjectData);

    try {
      await Promise.all([updateSubjectDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SUBJECT_MODIFY_SUBJECT_FAIL({
          error: errorMessage
        })
      );
    }
    toastr.success('', 'Subject updated successfully');

    return dispatch(SUBJECT_MODIFY_SUBJECT_SUCCESS(subjectData));
  };
};

export const subjectsCleanUp = () => dispatch => dispatch(SUBJECT_CLEAN_UP());
