import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import firebase from 'firebase.js';

export const FIELD_LEVELS_FETCH_DATA_INIT = createAction('FIELD_LEVELS_FETCH_DATA_INIT');
export const FIELD_LEVELS_FETCH_DATA_SUCCESS = createAction(
  'FIELD_LEVELS_FETCH_DATA_SUCCESS'
);
export const FIELD_LEVELS_FETCH_DATA_FAIL = createAction('FIELD_LEVELS_FETCH_DATA_FAIL');

export const FIELD_LEVELS_DELETE_LEVEL_INIT = createAction('FIELD_LEVELS_DELETE_LEVEL_INIT');
export const FIELD_LEVELS_DELETE_LEVEL_SUCCESS = createAction(
  'FIELD_LEVELS_DELETE_LEVEL_SUCCESS'
);
export const FIELD_LEVELS_DELETE_LEVEL_FAIL = createAction('FIELD_LEVELS_DELETE_LEVEL_FAIL');

export const FIELD_LEVELS_CLEAR_DATA = createAction('FIELD_LEVELS_CLEAR_DATA');

export const FIELD_LEVELS_CREATE_LEVEL_INIT = createAction('FIELD_LEVELS_CREATE_LEVEL_INIT');
export const FIELD_LEVELS_CREATE_LEVEL_SUCCESS = createAction(
  'FIELD_LEVELS_CREATE_LEVEL_SUCCESS'
);
export const FIELD_LEVELS_CREATE_LEVEL_FAIL = createAction('FIELD_LEVELS_CREATE_LEVEL_FAIL');

export const FIELD_LEVELS_MODIFY_LEVEL_INIT = createAction('FIELD_LEVELS_MODIFY_LEVEL_INIT');
export const FIELD_LEVELS_MODIFY_LEVEL_SUCCESS = createAction(
  'FIELD_LEVELS_MODIFY_LEVEL_SUCCESS'
);
export const FIELD_LEVELS_MODIFY_LEVEL_FAIL = createAction('FIELD_LEVELS_MODIFY_LEVEL_FAIL');

export const FIELD_LEVELS_CLEAN_UP = createAction('FIELD_LEVELS_CLEAN_UP');

export const FIELD_LEVELS_CLEAR_DATA_LOGOUT = createAction('FIELD_LEVELS_CLEAR_DATA_LOGOUT');

export const fetchLevels = (all = false) => {
  return async (dispatch) => {

    dispatch(FIELD_LEVELS_FETCH_DATA_INIT());

    let levels;

    try {
      levels = (
        await firebase
          .database()
          .ref('fieldLevels')
          .orderByChild('order')
          .once('value')
      ).val();
    } catch (error) {
      toastr.error('', error);
      return dispatch(FIELD_LEVELS_FETCH_DATA_FAIL({ error }));
    }
    const levelsData = levels
      ? Object.values(levels)
      : [];
    const results = all ? levelsData : levelsData.filter(item => !item.isObsolete);

    return dispatch(
      FIELD_LEVELS_FETCH_DATA_SUCCESS({ levels: results })
    );
  };
};

export const deleteLevel = id => {
  return async (dispatch, getState) => {
    dispatch(FIELD_LEVELS_DELETE_LEVEL_INIT());
    const { locale } = getState().preferences;

    const deleteLevelTask = firebase
      .database()
      .ref(`fieldLevels/${id}`)
      .remove();

    try {
      await Promise.all([deleteLevelTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        FIELD_LEVELS_DELETE_LEVEL_FAIL({
          error: errorMessage
        })
      );
    }

    toastr.success('', 'The level was deleted.');
    return dispatch(FIELD_LEVELS_DELETE_LEVEL_SUCCESS({ id }));
  };
};

export const clearLevelsData = () => {
  return dispatch => {
    dispatch(FIELD_LEVELS_CLEAR_DATA());
  };
};

export const clearLevelsDataLogout = () => {
  return dispatch => {
    dispatch(FIELD_LEVELS_CLEAR_DATA_LOGOUT());
  };
};

export const createLevel = (data ) => {
  return async (dispatch, getState) => {
    dispatch(FIELD_LEVELS_CREATE_LEVEL_INIT());
    const { locale } = getState().preferences;

    const uid = firebase.database().ref().child('levels').push().key;
    const level = {...data, id: uid}
    try {
      // const incrementId = await firebase.database().ref('levels/incrementId').once('value');
      // console.log(incrementId.val());
      await firebase
        .database()
        .ref(`fieldLevels/${uid}`)
        .set(level);
      // await firebase.database().ref('levels/incrementId').transaction(
      //   currentIncrementId => (currentIncrementId ? currentIncrementId + 10 : 10));
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        FIELD_LEVELS_CREATE_LEVEL_FAIL({
          error: errorMessage
        })
      );
    }
    toastr.success('', 'Level created successfully');
    return dispatch(FIELD_LEVELS_CREATE_LEVEL_SUCCESS(level));
  };
};

export const modifyLevel = (levelData) => {
  return async (dispatch, getState) => {
    dispatch(FIELD_LEVELS_MODIFY_LEVEL_INIT());
    const { locale } = getState().preferences;

    const updateLevelDbTask = firebase
      .database()
      .ref(`fieldLevels/${levelData.id}`)
      .update(levelData);

    try {
      await Promise.all([updateLevelDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        FIELD_LEVELS_MODIFY_LEVEL_FAIL({
          error: errorMessage
        })
      );
    }
    toastr.success('', 'Level updated successfully');

    return dispatch(FIELD_LEVELS_MODIFY_LEVEL_SUCCESS(levelData));
  };
};

export const levelsCleanUp = () => dispatch => dispatch(FIELD_LEVELS_CLEAN_UP());
