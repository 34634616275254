import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import { createUser, modifyUser } from 'state/actions/students';
import paths from 'pages/Router/paths';
import firebase from 'firebase.js';
import { Card, Tab, Tabs, Accordion } from 'react-bootstrap';
import BasicForm from '../../components/BasicForm';
import ProfileLevelForm from '../../components/ProfileLevelForm';
import TestResult from '../../components/TestResult';
import TutorMapping from '../../components/TutorMapping';

const Student = () => {
  const { id } = useParams();

  const { isAdmin } = useSelector(
    state => ({
      success: state.students.success,
      isAdmin: state.auth.userData.isAdmin
    }),
    shallowEqual
  );

  const [user, setUser] = useState({
    file: null,
    dob: new Date().toDateString(),
    gender: 'male',
    createdAt: new Date().toUTCString(),
    status: 'active',
    countryId: 'SG',
    profileLevel: {
      subjects: [],
      startDate: new Date().toDateString(),
      groupTuition: 0
    }
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const response = (
        await firebase
          .database()
          .ref(`students/${id}`)
          .once('value')
      ).val();
      return response;
    };
    if (id) {
      fetchUserData()
        .then(userData => {
          setUser(userData);
        })
        .catch(() => {
          setUser({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;
  const userForm =
    !user.email && id ? (
      <ClipLoader/>
    ) : (
      <BasicForm
        isEditing={isEditing}
        userData={user}
        action={isEditing ? modifyUser : createUser}
      />
    );

  const profileForm =
    !user.email && id ? (
      <ClipLoader/>
    ) : (
      <ProfileLevelForm
        userData={user}
        action={modifyUser}
      />
    );
  const redirectAdmin = !isAdmin && <Redirect to={paths.ROOT}/>;
  const redirect = (user.error) && <Redirect to={paths.MANAGE_STUDENT}/>;
  const profileLevel = user.profileLevel || {};

  const subjects = profileLevel.subjects && Object.keys(profileLevel.subjects) || [];

  return (
    <>
      {redirectAdmin}
      {redirect}
      <Card>
        <Card.Header>
          <Card.Title as="h5">{isEditing ? 'Edit Student' : 'New Student'}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Tabs defaultActiveKey="basic">
            <Tab eventKey="basic" title="Basic Profile">
              {userForm}
            </Tab>
            <Tab eventKey="profile" title="Profile Level" disabled={!id}>
              {profileForm}
            </Tab>
            <Tab eventKey="test" title="Test result" disabled={!id}>
              <TestResult userData={user} action={modifyUser} />
            </Tab>
            <Tab eventKey="subject" title="Subjects" disabled={!id}>
              {subjects.length > 0}
              <Accordion>
                {subjects.map(subjectId => <TutorMapping userData={user} key={`matching_${subjectId}`} studentId={id} subjectId={subjectId}/>)}
              </Accordion>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>

    </>
  );
};

export default Student;
