import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';
import Switch from 'react-bootstrap/Switch';

import paths from 'pages/Router/paths';
import { usersCleanUp } from 'state/actions/users';
import { useChangeHandler, useFormatMessage } from 'hooks';
import { validateEmail } from 'utils';
import './BasicForm.scss';
import DatePicker from '../DatePicker';
import { fetchCountries } from '../../state/actions/countries';
import { genderOptions } from '../options';

const BasicForm = ({ isEditing, isTutor, userData, action }) => {
  const { countries, loading } = useSelector(
    state => ({
      countries: state.countries.data,
      loading: state.students.loading
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCountries());
    return () => dispatch(usersCleanUp());
  }, [dispatch]);

  const [user, setUser] = useState(userData);

  const onChangeHandler = useChangeHandler(setUser);

  const onFileChangedHandler = event => {
    const file = event.target.files[0];
    setUser(prevState => ({ ...prevState, file, logoUrl: null }));
  };

  const onSubmitHandler = event => {
    event.preventDefault();
    dispatch(
      action({ ...user, createdAt: user.createdAt, isEditing })
    );
  };

  let emailInput = {
    modifier: null,
    message: { modifier: null, content: null }
  };
  const invalidEmail = user.email && !validateEmail(user.email);

  const invalidEmailMessage = useFormatMessage('UserForm.invalidEmail');

  if (invalidEmail) {
    emailInput = {
      modifier: 'danger',
      message: {
        modifier: 'danger',
        content: invalidEmailMessage
      }
    };
  }

  const canSubmit =
    user.name && user.email && !invalidEmail;

  const imagePreviewUrl = !user.logoUrl
    ? user.file && URL.createObjectURL(user.file)
    : user.logoUrl;

  const goBackMessage = useFormatMessage('UserForm.goBack');

  const emailMessage = useFormatMessage('UserForm.email');

  return (
    <form onSubmit={onSubmitHandler}>
      <Row>
        <Col lg={6} md={12}>
          <h5>Basic Information</h5>
          <hr/>
          {isTutor &&
            <Form.Group>
              <Form.Label htmlFor="tutor-status">Status</Form.Label>
              <Switch
                id="tutor-status"
                type="switch"
                name="status"
                label=""
                checked={!!user.status}
                onChange={onChangeHandler}
              />
            </Form.Group>
          }
          {isEditing ? (
            <Form.Group>
              <Form.Label htmlFor="basic-email">{emailMessage}</Form.Label>
              <Form.Control
                id="basic-email"
                type="text"
                readOnly="readOnly"
                className="no-border"
                value={user.email}
              />
            </Form.Group>
          ) : (
            <Form.Group className="is-horizontal">
              <Form.Label htmlFor="basic-email">{emailMessage}</Form.Label>
              <Form.Control
                id="basic-email"
                className={`${emailInput.modifier}`}
                type="email"
                required
                name="email"
                value={user.email}
                onChange={onChangeHandler}
              />
              {emailInput.message.content && (
                <p
                  className={`help text-${emailInput.message.modifier}`}
                >
                  {emailInput.message.content}
                </p>
              )}
            </Form.Group>
          )}

          <Form.Group>
            <Form.Label htmlFor="basic-name">
              {useFormatMessage('UserForm.name')}
            </Form.Label>
            <Form.Control
              id="basic-name"
              type="text"
              required
              name="name"
              value={user.name}
              onChange={onChangeHandler}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label htmlFor="basic-mobile">
              Mobile
            </Form.Label>
            <Form.Control
              id="basic-mobile"
              type="text"
              required
              name="mobile"
              value={user.mobile}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Gender</Form.Label>
            {Object.values(genderOptions).map(value => <Form.Check
              key={value}
              label={value}
              type='radio'
              name='gender'
              id={`gender-${value}`}
              value={value.toLowerCase()}
              checked={user.gender === value.toLowerCase()}
              onChange={onChangeHandler}
            />)}


          </Form.Group>
          <Form.Group >
            <Form.Label>Birthday</Form.Label>
            <div className="mb-2">
              <DatePicker
                name="dob"
                date={new Date(user.dob || new Date().toDateString())}
                setState={setUser}
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Photo</Form.Label>
            <div className="file has-name">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  accept="image/*"
                  onChange={onFileChangedHandler}
                />
              </label>
            </div>
            {imagePreviewUrl && (
              <>
                <div className="is-user-avatar image has-max-width is-aligned-center">
                  <img
                    className="user-avatar"
                    src={imagePreviewUrl}
                    alt="User profile logo preview"
                  />
                </div>
                <hr/>
              </>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="basic-info1">Additional Info 1</Form.Label>
            <Form.Control as='textarea' id="basic-info1" value={user.info1} name="info1" onChange={onChangeHandler} />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="basic-info2">Additional Info 2</Form.Label>
            <Form.Control as='textarea' id="basic-info2" value={user.info2} name="info2"  onChange={onChangeHandler} />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="basic-info3">Additional Info 3</Form.Label>
            <Form.Control as='textarea' id="basic-info3" value={user.info3} name="info3"  onChange={onChangeHandler} />
          </Form.Group>

          <hr/>

          <Form.Group>
            <button
              type="submit"
              className={`btn btn-primary ${loading &&
              'is-loading'}`}
              disabled={!canSubmit}
            >
              <span>{useFormatMessage('UserForm.submit')}</span>
            </button>
            <Link to={isTutor ? paths.MANAGE_TUTOR : paths.MANAGE_STUDENT} className="button">
              {goBackMessage}
            </Link>
          </Form.Group>
        </Col>
        <Col lg={6} md={12}>
          <h5>Address</h5>
          <hr/>
          <Form.Group>
            <Form.Group>
              <Form.Label> House/blk number </Form.Label>
              <Form.Control type='text' value={user.houseNumber} name='houseNumber' onChange={onChangeHandler}/>
            </Form.Group>
            <Form.Group>
              <Form.Label>Building name </Form.Label>
              <Form.Control type='text' value={user.buildingName} name='buildingName' onChange={onChangeHandler} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Street line 1 </Form.Label>
              <Form.Control type='text' value={user.street1} name='street1' onChange={onChangeHandler} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Street line 2 </Form.Label>
              <Form.Control type='text' value={user.street2} name='street2' onChange={onChangeHandler} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Postal code </Form.Label>
              <Form.Control type='text' value={user.postcode} name='postcode' onChange={onChangeHandler} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Country
              </Form.Label>
              <Form.Control as="select"
                type="text"
                name="countryId"
                required
                value={user.countryId}
                onChange={onChangeHandler}
              >
                {Object.values(countries).map((value) => <option key={value.code}
                                                                 value={value.code}>{value.name}</option>)}
              </Form.Control>
            </Form.Group>
          </Form.Group>


        </Col>
      </Row>
    </form>
  );
};

export default BasicForm;
