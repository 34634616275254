import { createReducer } from 'redux-act';

import {
  STUDENT_FETCH_DATA_INIT,
  STUDENT_FETCH_DATA_SUCCESS,
  STUDENT_FETCH_DATA_FAIL,
  STUDENT_DELETE_STUDENT_INIT,
  STUDENT_DELETE_STUDENT_SUCCESS,
  STUDENT_DELETE_STUDENT_FAIL,
  STUDENT_CLEAR_DATA,
  STUDENT_CREATE_STUDENT_INIT,
  STUDENT_CREATE_STUDENT_SUCCESS,
  STUDENT_CREATE_STUDENT_FAIL,
  STUDENT_MODIFY_STUDENT_INIT,
  STUDENT_MODIFY_STUDENT_SUCCESS,
  STUDENT_MODIFY_STUDENT_FAIL,
  STUDENT_CLEAN_UP,
  STUDENT_CLEAR_DATA_LOGOUT
} from 'state/actions/students';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false
};

export const studentsReducer = createReducer(
  {
    [STUDENT_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true
    }),
    [STUDENT_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.users,
      loading: false,
      error: null
    }),
    [STUDENT_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [STUDENT_DELETE_STUDENT_INIT]: state => ({
      ...state,
      loading: true
    }),
    [STUDENT_DELETE_STUDENT_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter(elem => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true
    }),
    [STUDENT_DELETE_STUDENT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [STUDENT_CLEAR_DATA]: state => ({
      ...initialState,
      data: state.data
    }),
    [STUDENT_CREATE_STUDENT_INIT]: state => ({
      ...state,
      loading: true
    }),
    [STUDENT_CREATE_STUDENT_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload.user),
      loading: false,
      error: null,
      success: true
    }),
    [STUDENT_CREATE_STUDENT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [STUDENT_MODIFY_STUDENT_INIT]: state => ({
      ...state,
      loading: true
    }),
    [STUDENT_MODIFY_STUDENT_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.map(elem => {
        if (elem.id === payload.id) {
          return {
            name: payload.user.name,
            location: payload.user.location,
            id: payload.id,
            logoUrl: payload.user.logoUrl,
            createdAt: payload.user.createdAt,
            email: elem.email
          };
        }
        return elem;
      }),
      loading: false,
      error: null,
      success: true
    }),
    [STUDENT_MODIFY_STUDENT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [STUDENT_CLEAN_UP]: state => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false
    }),
    [STUDENT_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState
    })
  },
  initialState
);
