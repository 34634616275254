import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';


import { authReducer } from './auth';
import { usersReducer } from './users';
import { preferencesReducer } from './preferences';
import { levelsReducer } from './browsingLevels';
import { fieldLevelsReducer } from './fieldLevels';
import { fieldSubjectsReducer } from './subjects';
import { fieldSchoolsReducer } from './schools';
import { countriesReducer } from './countries';
import { studentsReducer } from './students';
import { tutorsReducer} from './tutors';
import { ordersReducer} from './orders';

export default combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error', 'loading']
    },
    authReducer
  ),
  preferences: persistReducer(
    { key: 'preferences', storage: new CookieStorage(Cookies) },
    preferencesReducer
  ),
  users: persistReducer(
    {
      key: 'users',
      storage,
      blacklist: ['error', 'loading']
    },
    usersReducer
  ),
  levels: persistReducer(
    {
      key: 'levels',
      storage,
      blacklist: ['error', 'loading']
    },
    levelsReducer
  ),
  fieldLevels: persistReducer(
    {
      key: 'fieldLevels',
      storage,
      blacklist: ['error', 'loading']
    },
    fieldLevelsReducer
  ),
  subjects: persistReducer(
    {
      key: 'subjects',
      storage,
      blacklist: ['error', 'loading']
    },
    fieldSubjectsReducer
  ),
  schools: persistReducer(
    {
      key: 'schools',
      storage,
      blacklist: ['error', 'loading']
    },
    fieldSchoolsReducer
  ),
  countries: persistReducer(
    {
      key: 'countries',
      storage,
      blacklist: ['error', 'loading']
    },
    countriesReducer
  ),
  students: persistReducer(
    {
      key: 'students',
      storage,
      blacklist: ['error', 'loading']
    },
    studentsReducer
  ),
  tutors: persistReducer(
    {
      key: 'tutors',
      storage,
      blacklist: ['error', 'loading']
    },
    tutorsReducer
  ),
  orders: persistReducer(
    {
      key: 'orders',
      storage,
      blacklist: ['error', 'loading']
    },
    ordersReducer
  ),
  toastr: toastrReducer,
});
