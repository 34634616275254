import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import SubjectForm from '../../components/SubjectForm';
import { createSubject, modifySubject } from '../../state/actions/subjects';
import firebase from '../../firebase';
import paths from '../Router/paths';
import SettingForm from '../../components/SubjectForm/SettingForm';

const Subject = () => {
  const { id } = useParams();

  const { success, isAdmin } = useSelector(
    state => ({
      success: state.subjects.success,
      isAdmin: state.auth.userData.isAdmin
    }),
    shallowEqual
  );

  const [subject, setSubject] = useState({
    name: '',
    levelStart: '',
    levelEnd: '',
    countryId: 'SG',
    isObsolete: false,
    createdAt: new Date().toUTCString(),
    order: 0,
    settings: {}

  });


  useEffect(() => {
    const fetchSubjectData = async () => {
      const response = (
        await firebase
          .database()
          .ref(`subjects/${id}`)
          .once('value')
      ).val();
      return response;
    };

    if (id) {
      fetchSubjectData()
        .then(subjectData => {
          console.log(subjectData);
          setSubject({
            ...subjectData,
            createdAt: subjectData.createdAt,
            id
          });
        })
        .catch(() => {
          setSubject({ error: true });
        });
    }

  }, [id]);

  const isEditing = !!id;
  const redirectAdmin = !isAdmin && <Redirect to={paths.ROOT}/>;
  const redirect = (subject.error || success) && <Redirect to={paths.MANAGE_SUBJECT}/>;
  const subjectForm =
    !subject.name && id ? (
      <ClipLoader/>
    ) : (
      <SubjectForm
        subjectData={subject}
        action={isEditing ? modifySubject : createSubject}
      />
    );
  const settingForm =
    !subject.name && id ? (
      <ClipLoader/>
    ) : (
      <SettingForm
        subjectData={subject}
        action={modifySubject}
      />
    );
  return (
    <>
      {redirectAdmin}
      {redirect}
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">{isEditing ? 'Edit Subject' : 'Add Subject'}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Tabs defaultActiveKey="basic">
                <Tab eventKey="basic" title="Basic Info">
                  {subjectForm}
                </Tab>
                <Tab eventKey="setting" title="Settings" disabled={!id}>
                  {settingForm}
                </Tab>
              </Tabs>

            </Card.Body>

          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Subject;
