import React  from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

import windowSize from 'react-window-size';

import NavIcon from '../NavIcon';
import NavBadge from '../NavBadge';
import { collapseMenuAction, navContentLeave } from '../../../../../state/actions/preferences';

const NavItem = ({windowWidth, item}) => {
  const dispatch = useDispatch();

  const { layout } = useSelector(
    state => ({
      layout: state.layout
    }),
    shallowEqual
  );
  const onItemClick = () => {
    dispatch(collapseMenuAction());
  };

  const onItemLeave = () => {
    dispatch(navContentLeave());
  };
  let itemTitle = item.title;
  if (item.icon) {
    itemTitle = <span className="pcoded-mtext">{item.title}</span>;
  }

  let itemTarget = '';
  if (item.target) {
    itemTarget = '_blank';
  }

  let subContent;
  if (item.external) {
    subContent = (
      <a href={item.url} target='_blank' rel='noopener noreferrer'>
        <NavIcon items={item}/>
        {itemTitle}
        <NavBadge layout={layout} items={item}/>
      </a>
    );
  } else {
    subContent = (
      <NavLink to={item.url} className="nav-link" exact target={itemTarget}>
        <NavIcon items={item}/>
        {itemTitle}
        <NavBadge layout={layout} items={item}/>
      </NavLink>
    );
  }
  let mainContent = '';
  if (layout === 'horizontal') {
    mainContent = (
      <li onClick={onItemLeave}>{subContent}</li>
    );
  } else if (windowWidth < 992) {
    mainContent = (
      <li className={item.classes} onClick={onItemClick}>{subContent}</li>
    );
  } else {
    mainContent = (
      <li className={item.classes}>{subContent}</li>
    );
  }

  return (
    <>
      {mainContent}
    </>
  );
};

export default withRouter(windowSize(NavItem));
