import React from 'react';
import { Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const QRCode = require('qrcode.react');

const QRCodePage = () => {
  const { id } = useParams();
  return (
    <Row style={{justifyContent: 'center', marginTop: '20px'}}>
        {id? <QRCode size={256} value={id} /> : 'Missing Order ID' }
    </Row>
  );
};

export default QRCodePage;
