import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage, useFormatDate } from 'hooks';
import Table from 'components/Table';
import { fetchLevels, deleteLevel, clearLevelsData } from '../../state/actions/browsingLevels';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import NavSearch from 'components/Layout/NavSearch';
import { accessByOptions } from '../../components/options';
import { sortDate } from '../../utils';

const BrowsingLevels = () => {
  const { levelsList, isAdmin, error, loading, deleted } = useSelector(
    state => ({
      levelsList: state.levels.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.levels.error,
      loading: state.levels.loading,
      deleted: state.levels.deleted
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    levelId: null,
    isOpen: false
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState();

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchLevels());
    }

    return () => dispatch(clearLevelsData());
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal(prevState => ({
        levelId: null,
        isOpen: !prevState.isOpen
      }));
    }
  }, [deleted, loading]);

  const redirect = !isAdmin && <Redirect to={paths.ROOT}/>;

  const onRemoveButtonClickHandler = levelId => {
    setDeleteModal(prevState => ({
      levelId,
      isOpen: !prevState.isOpen
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ levelId: null, isOpen: false });
  };

  const onDeleteLevelHandler = () => {
    dispatch(deleteLevel(deleteModal.levelId));
  };

  const columns = [
    {
      Header: useFormatMessage('Levels.name'),
      accessor: 'name'
    },
    {
      Header: useFormatMessage('Levels.accessBy'),
      accessor: 'accessBy',
      Cell: ({ row }) => accessByOptions[row.original.accessBy],
    },
    {
      Header: useFormatMessage('Levels.description'),
      accessor: 'description'
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          })}
        </small>
      )
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          {!row.original.isAdmin && (
            <div className="button-actions is-right">
              <Link
                to={`/browsing-levels/${row.original.id}`}
                className="btn btn-sm btn-primary"
              >
                <i className="feather icon-edit"/>
              </Link>

              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={() => onRemoveButtonClickHandler(row.original.id)}
              >
                <i className="feather icon-trash"/>
              </button>
            </div>
          )}
        </>
      ),
      disableSortBy: true
    }
  ];

  const data = search
    ? levelsList.filter(el => {
      const clonedElem = { name: el.name, description: el.description };
      return Object.values(clonedElem).some(field =>
        field.toLowerCase().includes(search.toLowerCase())
      );
    })
    : levelsList;

  const deleteMessage = useFormatMessage('Levels.delete');

  const confirmMessage = useFormatMessage('Levels.confirm');

  const permDeleteMessage = useFormatMessage('Levels.permDelete');

  const cancelMessage = useFormatMessage('Levels.cancel');

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteLevelHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <Card>
        <Card.Header>
          <div className='d-flex flex-row align-items-center'>
            <Card.Title as="h5">{useFormatMessage('Levels.levels')}</Card.Title>
            <NavSearch handleChange={setSearch} />
            <Link to={paths.ADD_LEVEL} className="btn btn-light ml-auto">
              {useFormatMessage('Levels.newLevel')}
            </Link>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="b-table">
            {loading ? <ClipLoader/> : <Table columns={columns} data={data}/>}
            {error && 'Show error'}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default BrowsingLevels;
