import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withRouter } from 'react-router-dom';
import windowSize from 'react-window-size';

import NavLogo from './NavLogo';
import NavContent from './NavContent';
import OutsideClick from './OutsideClick';
import { collapseMenuAction, changeLayout } from '../../../state/actions/preferences';
import navigation from '../../../menu-items';

const Navigation = ({windowWidth}) => {

  const {
    layout,
    preLayout,
    collapseMenu,
    layoutType,
    navBackColor,
    navBackImage,
    navIconColor,
    navBrandColor,
    layout6Background,
    layout6BackSize,
    navFixedLayout,
    boxLayout,
    navDropdownIcon,
    navListIcon,
    navActiveListColor,
    navListTitleColor,
    navListTitleHide
  } = useSelector(
    state => ({
      layout: state.preferences.layout,
      preLayout: state.preferences.preLayout,
      collapseMenu: state.preferences.collapseMenu,
      layoutType: state.preferences.layoutType,
      navBackColor: state.preferences.navBackColor,
      navBackImage: state.preferences.navBackImage,
      navIconColor: state.preferences.navIconColor,
      navBrandColor: state.preferences.navBrandColor,
      layout6Background: state.preferences.layout6Background,
      layout6BackSize: state.preferences.layout6BackSize,
      navFixedLayout: state.preferences.navFixedLayout,
      boxLayout: state.preferences.boxLayout,
      navDropdownIcon: state.preferences.navDropdownIcon,
      navListIcon: state.preferences.navListIcon,
      navActiveListColor: state.preferences.navActiveListColor,
      navListTitleColor: state.preferences.navListTitleColor,
      navListTitleHide: state.preferences.navListTitleHide
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const onChangeLayout = (value) => {
    dispatch(changeLayout({ value }));
  };

  const onToggleNavigation = () => {
    dispatch(collapseMenuAction());
  };

  const resize = () => {
    const contentWidth = document.getElementById('root').clientWidth;

    if (layout === 'horizontal' && contentWidth < 992) {
      onChangeLayout('vertical');
    }
  };

  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);
  });


  let navClass = [
    'pcoded-navbar'
  ];

  if (preLayout !== null && preLayout !== '' && preLayout !== 'layout-6' && preLayout !== 'layout-8') {
    navClass = [...navClass, preLayout];
  } else {
    navClass = [
      ...navClass,
      layoutType,
      navBackColor,
      navBrandColor,
      `drp-icon-${navDropdownIcon}`,
      `menu-item-icon-${navListIcon}`,
      navActiveListColor,
      navListTitleColor
    ];

    if (layout === 'horizontal') {
      navClass = [...navClass, 'theme-horizontal'];
    }

    if (navBackImage) {
      navClass = [...navClass, navBackImage];
    }

    if (navIconColor) {
      navClass = [...navClass, 'icon-colored'];
    }

    if (!navFixedLayout) {
      navClass = [...navClass, 'menupos-static'];
    }

    if (navListTitleHide) {
      navClass = [...navClass, 'caption-hide'];
    }
  }

  if (windowWidth < 992 && collapseMenu) {
    navClass = [...navClass, 'mob-open'];
  } else if (collapseMenu) {
    navClass = [...navClass, 'navbar-collapsed'];
  }

  if (preLayout === 'layout-6') {
    document.body.classList.add('layout-6');
    document.body.style.backgroundImage = layout6Background;
    document.body.style.backgroundSize = layout6BackSize;
  }

  if (preLayout === 'layout-8') {
    document.body.classList.add('layout-8');
  }

  if (layoutType === 'dark') {
    document.body.classList.add('datta-dark');
  } else {
    document.body.classList.remove('datta-dark');
  }

  if (boxLayout) {
    document.body.classList.add('container');
    document.body.classList.add('box-layout');
  } else {
    document.body.classList.remove('container');
    document.body.classList.remove('box-layout');
  }

  let navContent = (
    <div className="navbar-wrapper">
      <NavLogo collapseMenu={collapseMenu} windowWidth={windowWidth} onToggleNavigation={onToggleNavigation}/>
      <NavContent navigation={navigation.items}/>
    </div>
  );
  if (windowWidth < 992) {
    navContent = (
      <OutsideClick>
        <div className="navbar-wrapper">
          <NavLogo collapseMenu={collapseMenu} windowWidth={windowWidth} onToggleNavigation={onToggleNavigation}/>
          <NavContent navigation={navigation.items}/>
        </div>
      </OutsideClick>
    );
  }

  return (
    <>
      <nav className={navClass.join(' ')}>
        {navContent}
      </nav>
    </>
  );
};


export default withRouter((windowSize(Navigation)));
