import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Login from 'pages/Login';
import Home from 'pages/Home';
import Users from 'pages/Users';
import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import NotFound from 'pages/NotFound';
import User from 'pages/User';
import Section from 'pages/Section';
import Level from 'pages/BrowsingLevel';
import BrowsingLevels from 'pages/BrowsingLevels';
import Statistic from 'pages/Statistic';
import paths from './paths';
import PrivateRoute from './PrivateRoute';
import Countries from '../Countries';
import FieldLevel from '../FieldLevel';
import FieldLevels from '../FieldLevels';
import Subject from '../Subject';
import Subjects from '../Subjects';
import FieldSchool from '../FieldSchool';
import FieldSchools from '../FieldSchools';
import Students from '../Students';
import Student from '../Student';
import Tutor from '../Tutor';
import Tutors from '../Tutors';
import TestResult from '../../components/TestResult';
import Orders from '../Orders';
import QRCodePage from '../QRCode';
import PaymentInstruction from '../PaymentInstruction';

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={paths.LOGIN} component={Login} />
        <Route exact path={paths.RESET_PASSWORD} component={ResetPassword} />
        <Route path={paths.CHART} component={TestResult} />
        <Route path={paths.QR_CODE} component={QRCodePage} />
        <Route path={paths.PAYMENT_INTRUCTION} component={PaymentInstruction} />
        <PrivateRoute path={paths.ADD_USER} component={User} />
        <PrivateRoute path={paths.MODIFY_USER} component={User} />
        <PrivateRoute path={paths.USERS} component={Users} />
        <PrivateRoute path={paths.PROFILE} component={Profile} />
        <PrivateRoute path={paths.SECTION} component={Section} />
        <PrivateRoute path={paths.ADD_LEVEL} component={Level} />
        <PrivateRoute path={paths.MODIFY_LEVEL} component={Level} />
        <PrivateRoute path={paths.MANAGE_LEVEL} component={BrowsingLevels} />
        <PrivateRoute path={paths.ADD_FIELD_LEVEL} component={FieldLevel} />
        <PrivateRoute path={paths.MODIFY_FIELD_LEVEL} component={FieldLevel} />
        <PrivateRoute path={paths.MANAGE_FIELD_LEVEL} component={FieldLevels} />
        <PrivateRoute path={paths.ADD_SUBJECT} component={Subject} />
        <PrivateRoute path={paths.MODIFY_SUBJECT} component={Subject} />
        <PrivateRoute path={paths.MANAGE_SUBJECT} component={Subjects} />
        <PrivateRoute path={paths.ADD_FIELD_SCHOOL} component={FieldSchool} />
        <PrivateRoute path={paths.MODIFY_FIELD_SCHOOL} component={FieldSchool} />
        <PrivateRoute path={paths.MANAGE_FIELD_SCHOOL} component={FieldSchools} />
        <PrivateRoute path={paths.MANAGE_COUNTRY} component={Countries} />
        <PrivateRoute path={paths.ADD_STUDENT} component={Student} />
        <PrivateRoute path={paths.MODIFY_STUDENT} component={Student} />
        <PrivateRoute path={paths.MANAGE_STUDENT} component={Students} />
        <PrivateRoute path={paths.ADD_TUTOR} component={Tutor} />
        <PrivateRoute path={paths.MODIFY_TUTOR} component={Tutor} />
        <PrivateRoute path={paths.MANAGE_TUTOR} component={Tutors} />
        <PrivateRoute path={paths.STATISTIC} component={Statistic} />
        <PrivateRoute path={paths.ORDERS} component={Orders} />
        <PrivateRoute exact path={paths.ROOT} component={Home} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterComponent;
