import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import FieldSchoolForm from '../../components/FieldSchoolForm';
import { createSchool, modifySchool } from '../../state/actions/schools';
import firebase from '../../firebase';
import paths from '../Router/paths';

const FieldSchool = () => {
  const { id } = useParams();

  const { success, isAdmin } = useSelector(
    state => ({
      success: state.schools.success,
      isAdmin: state.auth.userData.isAdmin
    }),
    shallowEqual
  );

  const [school, setSchool] = useState({
    name: '',
    countryId: 'SG',
    address1: '',
    address2: '',
    postcode: '',
    isObsolete: false,
    createdAt: new Date().toUTCString(),
    order: 0,

  });


  useEffect(() => {
    const fetchSchoolData = async () => {
      const response = (
        await firebase
          .database()
          .ref(`schools/${id}`)
          .once('value')
      ).val();
      return response;
    };

    if (id) {
      fetchSchoolData()
        .then(schoolData => {
          console.log(schoolData);
          setSchool({
            ...schoolData,
            createdAt: schoolData.createdAt,
            id
          });
        })
        .catch(() => {
          setSchool({ error: true });
        });
    }

  }, [id]);

  const isEditing = !!id;
  const redirectAdmin = !isAdmin && <Redirect to={paths.ROOT}/>;
  const redirect = (school.error || success) && <Redirect to={paths.MANAGE_FIELD_SCHOOL}/>;
  const schoolForm =
    !school.name && id ? (
      <ClipLoader/>
    ) : (
      <FieldSchoolForm
        schoolData={school}
        action={isEditing ? modifySchool : createSchool}
      />
    );
  return (
    <>
      {redirectAdmin}
      {redirect}
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">{isEditing ? 'Edit School' : 'Add School'}</Card.Title>
            </Card.Header>
            <Card.Body>
              {schoolForm}
            </Card.Body>

          </Card>
        </Col>
      </Row>
    </>
  );
};

export default FieldSchool;
