import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';

import paths from 'pages/Router/paths';
import { subjectsCleanUp, clearSubjectsData } from 'state/actions/subjects';
import { useChangeHandler, useFormatMessage } from 'hooks';
import { qtyRestrictOptions } from '../options';

const SettingForm = ({ subjectData, action }) => {
  const { loading } = useSelector(
    state => ({
      loading: state.subjects.loading
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!subjectData.id) dispatch(clearSubjectsData());
    return () => dispatch(subjectsCleanUp());
  }, [dispatch, subjectData.id]);

  const [settings, setSettings] = useState(subjectData.settings || {});

  const onChangeHandler = useChangeHandler(setSettings);

  const onSubmitHandler = event => {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    delete subjectData.settings;
    dispatch(
      action({ ...subjectData, settings })
    );
  };

  const goBackMessage = useFormatMessage('UserForm.goBack');
  const canSubmit = settings.originalPrice && parseFloat(settings.salePrice) < parseFloat(settings.originalPrice);
  return (
    <Row>
      <Col lg={12}>
        <form onSubmit={onSubmitHandler}>
          <hr/>
          <Form.Group>
            <Form.Label>
              Original Price
            </Form.Label>
            <Form.Control
              type="number"
              step="0.01"
              required
              name="originalPrice"
              value={settings.originalPrice}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Sale Price
            </Form.Label>
            <Form.Control
              type="number"
              step="0.01"
              name="salePrice"
              value={settings.salePrice}
              onChange={onChangeHandler}
            />
            {parseFloat(settings.salePrice) >= parseFloat(settings.originalPrice) && <p className='text-danger'>Sale price should be empty or less than Original Price </p>}
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Minimum QR lesson purchase
            </Form.Label>
            <Form.Control
              type="number"
              required
              name="minimumQRPurchase"
              value={settings.minimumQRPurchase}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Multiple quantity restriction</Form.Label>
            <Form.Control
              as="select"
              name="multipleQtyRestriction"
              required
              value={settings.multipleQtyRestriction}
              onChange={onChangeHandler}
            >
              {qtyRestrictOptions.map((value) => <option key={value} value={value}>{value}</option>)}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Expiry Multiplier (in days)
            </Form.Label>
            <Form.Control
              type="number"
              required
              name="expiryMultiplier"
              value={settings.expiryMultiplier}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <hr/>
          <Form.Group>
            <h6>Addon</h6>
            <Form.Group className="ml-2">
              <Form.Label>
                Expiry Days
              </Form.Label>
              <Form.Control
                type="number"
                required
                name="expiryDays"
                value={settings.expiryDays}
                onChange={onChangeHandler}
              />
            </Form.Group>
            <Form.Group className="ml-2">
              <Form.Label>
                Expiry Price
              </Form.Label>
              <Form.Control
                type="number"
                required
                name="expiryPrice"
                value={settings.expiryPrice}
                onChange={onChangeHandler}
              />
            </Form.Group>
          </Form.Group>
          <hr/>
          <Form.Group>
            <button
              type="submit"
              className={`btn btn-primary ${loading &&
              'is-loading'}`}
              disabled={!canSubmit}
            >
              <span>{useFormatMessage('UserForm.submit')}</span>
            </button>
            <Link to={paths.MANAGE_SUBJECT} className="button">
              {goBackMessage}
            </Link>
          </Form.Group>
        </form>
      </Col>
    </Row>
  );
};

SettingForm.propTypes = {
  subjectData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    countryId: PropTypes.string
  }),
  action: PropTypes.func.isRequired
};

export default SettingForm;
