import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';

import paths from 'pages/Router/paths';
import { subjectsCleanUp, clearSubjectsData } from 'state/actions/subjects';
import { useChangeHandler, useFormatMessage } from 'hooks';
import { fetchLevels } from '../../state/actions/fieldLevels';
import { fetchCountries } from '../../state/actions/countries';

const SubjectForm = ({ subjectData, action }) => {
  const { loading, levels, countries } = useSelector(
    state => ({
      loading: state.subjects.loading,
      levels: state.fieldLevels.data,
      countries: state.countries.data
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLevels);
    dispatch(fetchCountries);
    if (!subjectData.id) dispatch(clearSubjectsData());
    return () => dispatch(subjectsCleanUp());
  }, [subjectData.id, dispatch]);

  const [subject, setSubject] = useState(subjectData);

  const onChangeHandler = useChangeHandler(setSubject);

  const onSubmitHandler = event => {
    event.preventDefault();
    dispatch(
      action({ ...subject })
    );
  };

  const canSubmit = subject.name && subject.countryId;

  const goBackMessage = useFormatMessage('UserForm.goBack');

  return (
    <Row>
      <Col lg={12}>
        <form onSubmit={onSubmitHandler}>
          <Form.Group>
            <Form.Label htmlFor="subject-name">
              Subject Name *
            </Form.Label>
            <Form.Control
              id="subject-name"
              type="text"
              required
              name="name"
              value={subject.name}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="subject-levelStart">Level start
            </Form.Label>
            <Form.Control
              id="subject-levelStart"
              as="select"
              name="levelStart"
              required
              value={subject.levelStart}
              onChange={onChangeHandler}
            >
              {Object.values(levels).map((value) => <option key={value.code} value={value.id}>{value.name}</option>)}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="subject-levelEnd">Level end
            </Form.Label>
            <Form.Control
              id="subject-levelEnd"
              as="select"
              name="levelEnd"
              required
              value={subject.levelEnd}
              onChange={onChangeHandler}
            >
              {Object.values(levels).map((value) => <option key={value.id} value={value.id}>{value.name}</option>)}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="subject-country">Country *
            </Form.Label>
            <Form.Control
              id="subject-country"
              as="select"
              name="countryId"
              required
              value={subject.countryId}
              onChange={onChangeHandler}
            >
              {Object.values(countries).map((value) => <option key={value.code}
                                                               value={value.code}>{value.name}</option>)}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Check type="checkbox" onChange={onChangeHandler} checked={subject.isObsolete}
                        name="isObsolete" id="subject-isObsolete" label="Is Obsolete"/>
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="subject-order">
              Order
            </Form.Label>
            <Form.Control
              id="subject-order"
              type="text"
              name="order"
              value={subject.order}
              onChange={onChangeHandler}
            />
          </Form.Group>
          <hr/>
          <Form.Group>
            <button
              type="submit"
              className={`btn btn-primary ${loading &&
              'is-loading'}`}
              disabled={!canSubmit}
            >
              <span>{useFormatMessage('UserForm.submit')}</span>
            </button>
            <Link to={paths.MANAGE_SUBJECT} className="button">
              {goBackMessage}
            </Link>
          </Form.Group>
        </form>
      </Col>
    </Row>
  );
};

SubjectForm.propTypes = {
  subjectData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    countryId: PropTypes.string
  }),
  action: PropTypes.func.isRequired
};

export default SubjectForm;
