import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import windowSize from 'react-window-size';

import { collapseMenuAction } from 'state/actions/preferences';

const OutsideClick = ({windowWidth, children}) => {
  let wrapperRef;
  const {
    collapseMenu
  } = useSelector(
    state => ({
      collapseMenu: state.preferences.collapseMenu
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const setWrapperRef = (node) => {
    wrapperRef = node;
  };
  const onToggleNavigation = () => {
    dispatch(collapseMenuAction());
  };
  /**
   * close menu if clicked on outside of element
   */
  const handleOutsideClick = (event) => {
    if (wrapperRef && !wrapperRef.contains(event.target)) {
      if (windowWidth < 992 && collapseMenu) {
        onToggleNavigation();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
  });

  useEffect(() => {
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  return <div className="nav-outside" ref={setWrapperRef}>{children}</div>;
};
export default windowSize(OutsideClick);