import React  from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './ConfirmationModal.scss';

const ConfirmationModal = ({
  isActive,
  isLoading,
  title,
  body,
  confirmButtonMessage,
  onConfirmation,
  cancelButtonMessage,
  onCancel
}) => {

  return (
    <Modal show={isActive} onHide={onCancel} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          {cancelButtonMessage}
        </Button>
        {confirmButtonMessage && <button className={classNames('btn btn-primary', {
          'is-loading': isLoading
        })} disabled={isLoading} variant="primary" onClick={onConfirmation}>
          {confirmButtonMessage}
        </button>
        }
      </Modal.Footer>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  isActive: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  confirmButtonMessage: PropTypes.string.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  cancelButtonMessage: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ConfirmationModal;
