import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import BrowsingLevelForm from '../../components/BrowsingLevelForm';
import { createLevel, modifyLevel } from '../../state/actions/browsingLevels';
import firebase from '../../firebase';
import paths from '../Router/paths';

const Level = () => {
  const { id } = useParams();

  const { success, isAdmin } = useSelector(
    state => ({
      success: state.levels.success,
      isAdmin: state.auth.userData.isAdmin
    }),
    shallowEqual
  );

  const [level, setLevel] = useState({
    name: '',
    accessBy: 'tutor',
    description: '',
    createdAt: new Date().toUTCString(),
    order: 0,
    fields: {
      contact: false,
      email: false,
      houseNumber: false,
      buildingName: false,
      unitNumber: false,
      street1: false,
      street2: false,
      postcode: false
    }
  });

  useEffect(() => {
    const fetchLevelData = async () => {
      const response = (
        await firebase
          .database()
          .ref(`levels/${id}`)
          .once('value')
      ).val();
      return response;
    };

    if (id) {
      fetchLevelData()
        .then(levelData => {
          setLevel({
            ...levelData,
            createdAt: levelData.createdAt,
            id
          });
        })
        .catch(() => {
          setLevel({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;
  const redirectAdmin = !isAdmin && <Redirect to={paths.ROOT}/>;
  const redirect = (level.error || success) && <Redirect to={paths.MANAGE_LEVEL}/>;
  const levelForm =
    !level.name && id ? (
      <ClipLoader/>
    ) : (
      <BrowsingLevelForm
        levelData={level}
        action={isEditing ? modifyLevel : createLevel}
      />
    );
  return (
    <>
      {redirectAdmin}
      {redirect}
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">{isEditing ? 'Edit Browsing Level' : 'Add Browsing Level'}</Card.Title>
            </Card.Header>
            <Card.Body>
              {levelForm}
            </Card.Body>

          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Level;
