import { createReducer } from 'redux-act';

import {
  COUNTRY_FETCH_DATA_INIT,
  COUNTRY_FETCH_DATA_SUCCESS,
  COUNTRY_FETCH_DATA_FAIL,
  COUNTRY_TOGGLE_OBSOLETE_COUNTRY_INIT,
  COUNTRY_UNSET_COUNTRY_OBSOLETE_SUCCESS,
  COUNTRY_UNSET_COUNTRY_OBSOLETE_FAIL,
  COUNTRY_CLEAR_DATA,
  COUNTRY_CREATE_COUNTRY_INIT,
  COUNTRY_SET_COUNTRY_OBSOLETE_SUCCESS,
  COUNTRY_SET_COUNTRY_OBSOLETE_FAIL,
  COUNTRY_MODIFY_COUNTRY_INIT,
  COUNTRY_CLEAN_UP,
  COUNTRY_CLEAR_DATA_LOGOUT
} from '../../actions/countries';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false
};

export const countriesReducer = createReducer(
  {
    [COUNTRY_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true
    }),
    [COUNTRY_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.countries,
      loading: false,
      error: null
    }),
    [COUNTRY_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [COUNTRY_TOGGLE_OBSOLETE_COUNTRY_INIT]: state => ({
      ...state,
    }),
    [COUNTRY_UNSET_COUNTRY_OBSOLETE_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.map(elem => {
        if (elem.code === payload.code) {
          delete payload.id;
          delete payload.isObsolete;
          return payload;
        }
        return elem;
      }),
      loading: false,
      error: null,
    }),
    [COUNTRY_UNSET_COUNTRY_OBSOLETE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [COUNTRY_CLEAR_DATA]: state => ({
      ...initialState,
      data: state.data
    }),
    [COUNTRY_CREATE_COUNTRY_INIT]: state => ({
      ...state,
      loading: true
    }),
    [COUNTRY_SET_COUNTRY_OBSOLETE_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.map(elem => {
        if (elem.code === payload.code) {
          return payload;
        }
        return elem;
      }),
      loading: false,
      error: null,
      success: true
    }),
    [COUNTRY_SET_COUNTRY_OBSOLETE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [COUNTRY_MODIFY_COUNTRY_INIT]: state => ({
      ...state,
      loading: true
    }),
    [COUNTRY_SET_COUNTRY_OBSOLETE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [COUNTRY_CLEAN_UP]: state => ({
      ...state,
      loading: false,
      error: null,
      success: false,
    }),
    [COUNTRY_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState
    })
  },
  initialState
);
