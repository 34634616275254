import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import LineChart from './LineChart';

const Statistic = () => {

  return (
    <Row>
      <Col md={6}>
        <Card>
          <Card.Header>
            <Card.Title as="h5">Line Chart</Card.Title>
          </Card.Header>
          <Card.Body>
            <LineChart/>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Statistic;
