import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import firebase from 'firebase.js';
import { checkUserData, AUTH_UPDATE_USER_DATA } from './auth';
import { BROWSING_LEVELS_CLEAR_DATA_LOGOUT } from './browsingLevels';

export const STUDENT_FETCH_DATA_INIT = createAction('STUDENT_FETCH_DATA_INIT');
export const STUDENT_FETCH_DATA_SUCCESS = createAction(
  'STUDENT_FETCH_DATA_SUCCESS'
);
export const STUDENT_FETCH_DATA_FAIL = createAction('STUDENT_FETCH_DATA_FAIL');

export const STUDENT_DELETE_STUDENT_INIT = createAction('STUDENT_DELETE_STUDENT_INIT');
export const STUDENT_DELETE_STUDENT_SUCCESS = createAction(
  'STUDENT_DELETE_STUDENT_SUCCESS'
);
export const STUDENT_DELETE_STUDENT_FAIL = createAction('STUDENT_DELETE_STUDENT_FAIL');

export const STUDENT_CLEAR_DATA = createAction('STUDENT_CLEAR_DATA');

export const STUDENT_CREATE_STUDENT_INIT = createAction('STUDENT_CREATE_STUDENT_INIT');
export const STUDENT_CREATE_STUDENT_SUCCESS = createAction(
  'STUDENT_CREATE_STUDENT_SUCCESS'
);
export const STUDENT_CREATE_STUDENT_FAIL = createAction('STUDENT_CREATE_STUDENT_FAIL');

export const STUDENT_MODIFY_STUDENT_INIT = createAction('STUDENT_MODIFY_STUDENT_INIT');
export const STUDENT_MODIFY_STUDENT_SUCCESS = createAction(
  'STUDENT_MODIFY_STUDENT_SUCCESS'
);
export const STUDENT_MODIFY_STUDENT_FAIL = createAction('STUDENT_MODIFY_STUDENT_FAIL');

export const STUDENT_CLEAN_UP = createAction('STUDENT_CLEAN_UP');

export const STUDENT_CLEAR_DATA_LOGOUT = createAction('STUDENT_CLEAR_DATA_LOGOUT');

export const fetchUsers = () => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(STUDENT_FETCH_DATA_INIT());

    const { id } = getState().auth.userData;

    let users;

    try {
      users = (
        await firebase
          .database()
          .ref('students')
          .once('value')
      ).val();
    } catch (error) {
      toastr.error('', error);
      return dispatch(STUDENT_FETCH_DATA_FAIL({ error }));
    }

    const usersData = users
      ? Object.entries(users).map(([key, value]) => ({
          id: key,
          ...value
        }))
      : [];

    return dispatch(
      STUDENT_FETCH_DATA_SUCCESS({
        users: usersData.filter(user => user.id !== id)
      })
    );
  };
};

const deleteLogo = oldLogo => {
  if (!oldLogo.includes('firebasestorage')) {
    return null;
  }
  const logoPath = oldLogo
    .split('users%2F')
    .pop()
    .split('?alt=media')
    .shift();
  return firebase
    .storage()
    .ref(`users/${logoPath}`)
    .delete();
};

export const deleteUser = id => {
  return async (dispatch, getState) => {
    dispatch(STUDENT_DELETE_STUDENT_INIT());
    const { locale } = getState().preferences;
    const { logoUrl } = getState()
      .students.data.filter(user => user.id === id)
      .pop();

    const deleteLogoTask = logoUrl ? deleteLogo(logoUrl) : null;

    const deleteUserTask = firebase
      .database()
      .ref(`students/${id}`)
      .remove();

    try {
      await Promise.all([deleteLogoTask, deleteUserTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        STUDENT_DELETE_STUDENT_FAIL({
          error: errorMessage
        })
      );
    }

    toastr.success('', 'The student was deleted.');
    return dispatch(STUDENT_DELETE_STUDENT_SUCCESS({ id }));
  };
};

export const clearUsersData = () => {
  return dispatch => {
    dispatch(STUDENT_CLEAR_DATA());
  };
};

export const clearUsersDataLogout = () => {
  return dispatch => {
    dispatch(STUDENT_CLEAR_DATA_LOGOUT());
    dispatch(BROWSING_LEVELS_CLEAR_DATA_LOGOUT());
  };
};

const uploadLogo = (uid, file) => {
  const storageRef = firebase.storage().ref();

  const fileExtension = file.name.split('.').pop();

  const fileName = `${uid}.${fileExtension}`;

  return storageRef.child(`users/${fileName}`).put(file);
};

const getLogoUrl = (uid, file) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/users%2F${uid}_200x200.${fileExtension}?alt=media`;
};

export const createUser = (data) => {
  return async (dispatch, getState) => {
    dispatch(STUDENT_CREATE_STUDENT_INIT());
    const { locale } = getState().preferences;

    let response;
    try {
      const createUserAuth = firebase
        .functions()
        .httpsCallable('httpsCreateUser');

      response = await createUserAuth({ email: data.email, userType: 'student' });
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        STUDENT_CREATE_STUDENT_FAIL({
          error: errorMessage
        })
      );
    }

    const { uid } = response.data;

    let uploadLogoTask = null;
    let logoUrl = null;
    if (data.file) {
      logoUrl = getLogoUrl(uid, data.file);
      uploadLogoTask = uploadLogo(uid, data.file);
    }
    delete data.file;
    delete data.isProfile;
    delete data.isEditing;
    const student = {
      ...data,
      logoUrl,
      id: uid
    };
    const createUserDbTask = firebase
      .database()
      .ref(`students/${uid}`)
      .set(student);

    const actionCodeSettings = {
      url: process.env.REACT_APP_LOGIN_PAGE_URL,
      handleCodeInApp: true
    };

    const sendSignInLinkToEmailTask = firebase
      .auth()
      .sendSignInLinkToEmail(data.email, actionCodeSettings);

    try {
      await Promise.all([
        uploadLogoTask,
        createUserDbTask,
        sendSignInLinkToEmailTask
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        STUDENT_CREATE_STUDENT_FAIL({
          error: errorMessage
        })
      );
    }

    toastr.success('', 'Student created successfully');
    return dispatch(STUDENT_CREATE_STUDENT_SUCCESS({ user: response.data }));
  };
};

export const modifyUser = (data) => {
  console.log(data)
  return async (dispatch, getState) => {
    dispatch(STUDENT_MODIFY_STUDENT_INIT());
    // eslint-disable-next-line no-param-reassign
    delete data.isProfile;
    // eslint-disable-next-line no-param-reassign
    delete data.isEditing;

    const { locale } = getState().preferences;
    const { logoUrl } = getState()
          .students.data.filter(user => user.id === data.id)
          .pop();

    let deleteLogoTask;
    let uploadLogoTask;
    let newLogoUrl = null;
    if (data.file) {
      newLogoUrl = getLogoUrl(data.id, data.file);
      deleteLogoTask = logoUrl && deleteLogo(logoUrl);
      uploadLogoTask = uploadLogo(data.id, data.file);
    }

    const userData = {
      ...data,
      logoUrl:  newLogoUrl || logoUrl || null,
      userType: 'student',
      createdAt: data.createdAt || new Date().toUTCString()
    };

    const updateUserDbTask = firebase
      .database()
      .ref(`students/${data.id}`)
      .update(userData);

    try {
      await Promise.all([deleteLogoTask, uploadLogoTask, updateUserDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        STUDENT_MODIFY_STUDENT_FAIL({
          error: errorMessage
        })
      );
    }


    toastr.success('', 'Student updated successfully');

    return dispatch(STUDENT_MODIFY_STUDENT_SUCCESS({ user: { ...userData, id: data.id } }));
  };
};


export const mappingUser = (data) => {
  return async (dispatch, getState) => {
    dispatch(STUDENT_MODIFY_STUDENT_INIT());
    // eslint-di
    const { locale } = getState().preferences;

    const {studentData, tutorData} = data;

    const updateStudentDbTask = firebase
      .database()
      .ref(`students/${studentData.id}`)
      .update(studentData);

    let updateTutorDbTask = null;
    console.log(tutorData);
    if (tutorData) {
      updateTutorDbTask = firebase
        .database()
        .ref(`tutors/${tutorData.id}`)
        .update(tutorData);
    }
    try {
      if (updateTutorDbTask) {
        await Promise.all([updateStudentDbTask, updateTutorDbTask]);
      } else {
        await Promise.all([updateStudentDbTask]);
      }

    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        STUDENT_MODIFY_STUDENT_FAIL({
          error: errorMessage
        })
      );
    }

    toastr.success('', 'Tutor is matched successfully');

    return dispatch(STUDENT_MODIFY_STUDENT_SUCCESS({ user: studentData }));
  };
};

export const usersCleanUp = () => dispatch => dispatch(STUDENT_CLEAN_UP());
