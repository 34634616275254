import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatDate } from 'hooks';
import Table from 'components/Table';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import NavSearch from 'components/Layout/NavSearch';
import { clearOrdersData, fetchOrders, modifyOrder } from '../../state/actions/orders';
import { sortDate } from '../../utils';

const QRCode = require('qrcode.react');

const Orders = () => {
  const { orderList, isAdmin, error, loading, success } = useSelector(
    state => ({
      orderList: state.orders.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.orders.error,
      loading: state.orders.loading,
      success: state.orders.success
    }),
    shallowEqual
  );
  const [approveModal, setApproveModal] = useState({
    orderId: null,
    isOpen: false
  });

  const [qrModal, setQRModal] = useState({
    orderId: null,
    isOpen: false
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState();

  useEffect(() => {
    if (success && !loading) {
      setApproveModal(prevState => ({
        orderId: null,
        isOpen: !prevState.isOpen
      }));
    }
  }, [success, loading]);

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchOrders(true));
    }

    return () => dispatch(clearOrdersData());
  }, [dispatch, isAdmin]);

  const redirect = !isAdmin && <Redirect to={paths.ROOT}/>;

  const onApproveButtonClickHandler = orderId => {
    setApproveModal(prevState => ({
      orderId,
      isOpen: !prevState.isOpen
    }));
  };

  const onViewQrButtonClickHandler = orderId => {
    setQRModal(prevState => ({
      orderId,
      isOpen: !prevState.isOpen
    }));
  };

  const onCloseModalHandler = () => {
    setApproveModal({ orderId: null, isOpen: false });
  };

  const onCloseQRModalHandler = () => {
    setQRModal({ orderId: null, isOpen: false });
  };

  const onApproveHanlder = () => {
    const order = orderList.find(item => item.id === approveModal.orderId);
    order.status = 'approved';
    order.updatedAt = new Date().toDateString();
    dispatch(modifyOrder(order));
  };

  const columns = [
    {
      Header: 'Order ID',
      accessor: 'id'
    },
    {
      Header: 'Student Name',
      accessor: 'studentName'
    },
    {
      Header: 'Tutor Name',
      accessor: 'tutorName'
    },
    {
      Header: 'Subject',
      accessor: 'subjectName'
    },
    {
      Header: 'Total Price',
      accessor: 'total'
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.status.toUpperCase()}
        </small>
      )
    },
    {
      Header: 'Is Used',
      accessor: 'isUsed',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.isUsed ? 'Yes' : 'No'}
        </small>
      )
    },
    {
      Header: 'Qty Purchased',
      accessor: 'qty',
    },
    {
      Header: 'Qty Left',
      accessor: 'qtyLeft',
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          })}
        </small>
      )
    },
    {
      Header: 'Expired At',
      accessor: 'expiredDate',
      sortType: (a, b) => {
        a = new Date(a.original.expiredDate).getTime();
        b = new Date(b.original.expiredDate).getTime();
        return b > a ? 1 : -1;
      },
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.expiredDate, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          })}
        </small>
      )
    },
    {
      Header: 'Actions',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          {!row.original.isAdmin && (
            <div className="button-actions is-right">
              {row.original.status !== 'approved' &&
              <button
                type="button"
                className="label theme-bg text-white f-12"
                onClick={() => onApproveButtonClickHandler(row.original.id)}
              >
                Approve
              </button>
              }
              {row.original.status === 'approved' &&
              <button
                type="button"
                className="label theme-bg2 text-white f-12"
                onClick={() => onViewQrButtonClickHandler(row.original.id)}
              >
                View QR
              </button>
              }
            </div>
          )}
        </>
      ),
      disableSortBy: true
    }
  ];

  const data = search
    ? orderList.filter(el => {
      const clonedElem = {
        id: el.id,
        studentEmail: el.studentEmail,
        studentName: el.studentName,
        tutorEmail: el.tutorEmail,
        tutorName: el.tutorName,
        subjectName: el.subjectName
      };
      console.log(clonedElem);
      return Object.values(clonedElem).some(field =>
        field.toLowerCase().includes(search.toLowerCase())
      );
    })
    : orderList;

  return (
    <>
      {redirect}
      {approveModal.isOpen && (
        <ConfirmationModal
          isActive={approveModal.isOpen}
          isLoading={loading}
          confirmButtonMessage='Approve'
          title='Approve Order'
          body='Are you sure to approve this order?'
          cancelButtonMessage='Cancel'
          onConfirmation={onApproveHanlder}
          onCancel={onCloseModalHandler}
        />
      )}
      {qrModal.isOpen && (
        <ConfirmationModal
          isActive={qrModal.isOpen}
          title='QR Lesson Code'
          body={<QRCode size={256} value={qrModal.orderId} />}
          cancelButtonMessage='Cancel'
          onCancel={onCloseQRModalHandler}
        />
      )}
      <Card>
        <Card.Header>
          <div className='d-flex flex-row align-items-center'>
            <Card.Title as="h5">Manage orders </Card.Title>
            <NavSearch handleChange={setSearch}/>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="b-table">
            {loading ? <ClipLoader/> : <Table columns={columns} data={data}/>}
            {error && 'Show error'}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Orders;
