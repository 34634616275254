/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form } from 'react-bootstrap';

import paths from 'pages/Router/paths';
import { usersCleanUp } from 'state/actions/users';
import { useChangeHandler, useFormatDate, useFormatMessage } from 'hooks';
import { validateEmail } from 'utils';
import './UserForm.scss';
import DatePicker from '../DatePicker';

const UserForm = ({ isEditing, isProfile, userData, action }) => {
  const { loading } = useSelector(
    state => ({
      loading: state.users.loading
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(usersCleanUp());
  }, [dispatch]);

  const [user, setUser] = useState(userData);

  const onChangeHandler = useChangeHandler(setUser);

  const onFileChangedHandler = event => {
    const file = event.target.files[0];
    setUser(prevState => ({ ...prevState, file, logoUrl: null }));
  };

  const onSubmitHandler = event => {
    event.preventDefault();
    dispatch(
      action({ ...user, createdAt: user.createdAt, isEditing, isProfile })
    );
  };

  let emailInput = {
    modifier: null,
    message: { modifier: null, content: null }
  };

  const invalidEmail = user.email && !validateEmail(user.email);

  const invalidEmailMessage = useFormatMessage('UserForm.invalidEmail');

  if (invalidEmail) {
    emailInput = {
      modifier: 'danger',
      message: {
        modifier: 'danger',
        content: invalidEmailMessage
      }
    };
  }

  const canSubmit =
    user.name && user.location && user.createdAt && !invalidEmail;

  const imagePreviewUrl = !user.logoUrl
    ? user.file && URL.createObjectURL(user.file)
    : user.logoUrl;

  const goBackMessage = useFormatMessage('UserForm.goBack');

  const emailMessage = useFormatMessage('UserForm.email');

  const adminMessage = useFormatMessage('UserForm.admin');
  return (
    <Row>
      <Col lg={6} sm={12}>
        <Card>
          <Card.Header className="card-header">
            <span className="icon pr-1">
              <i className="feather icon-user default"/>
            </span>
            {useFormatMessage('UserForm.userInfo')}
          </Card.Header>
          <Card.Body>
            <form onSubmit={onSubmitHandler}>
              {isEditing ? (
                <Form.Group>
                  <Form.Label>{emailMessage}</Form.Label>

                  <input
                    type="text"
                    readOnly="readOnly"
                    className="form-control no-border"
                    value={user.email}
                  />

                </Form.Group>
              ) : (
                <div className="form-group is-horizontal">
                  <Form.Label>{emailMessage}</Form.Label>
                  <input
                    className={`form-control ${emailInput.modifier}`}
                    type="email"
                    required
                    name="email"
                    value={user.email}
                    onChange={onChangeHandler}
                  />
                  {emailInput.message.content && (
                    <p
                      className={`help text-${emailInput.message.modifier}`}
                    >
                      {emailInput.message.content}
                    </p>
                  )}
                </div>
              )}

              <Form.Group>
                <Form.Label>
                  {useFormatMessage('UserForm.name')}
                </Form.Label>
                <Form.Control
                  id="name"
                  type="text"
                  required
                  name="name"
                  value={user.name}
                  onChange={onChangeHandler}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  {useFormatMessage('UserForm.location')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="location"
                  required
                  value={user.location}
                  onChange={onChangeHandler}
                />
              </Form.Group>

              {!isProfile && (
                <Form.Group>
                  <Form.Check
                    custom
                    type="checkbox"
                    name="isAdmin"
                    id="isAdmin"
                    onChange={onChangeHandler}
                    label="Is Admin"
                  />
                </Form.Group>
              )}

              <Form.Group>
                <Form.Label>
                  {useFormatMessage('UserForm.created')}
                </Form.Label>
                <div className="mb-2">
                  <DatePicker
                    name="createdAt"
                    date={new Date(user.createdAt)}
                    setState={setUser}
                  />
                </div>
              </Form.Group>

              <hr/>

              <Form.Group>
                <Form.Label>
                  {useFormatMessage('UserForm.logo')}
                </Form.Label>
                <div className="file has-name">
                  <label className="file-label">
                    <input
                      className="file-input"
                      type="file"
                      accept="image/*"
                      onChange={onFileChangedHandler}
                    />
                  </label>
                </div>
              </Form.Group>

              <hr/>
              <Form.Group>

                <button
                  type="submit"
                  className={`btn btn-primary ${loading &&
                  'is-loading'}`}
                  disabled={!canSubmit}
                >
                  <span>{useFormatMessage('UserForm.submit')}</span>
                </button>
                {!isProfile && (
                  <Link to={paths.USERS} className="button">
                    {goBackMessage}
                  </Link>
                )}
              </Form.Group>
            </form>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6} sm={12}>

        <Card>
          <Card.Header className="card-header">
            <span className="icon pr-1">
              <i className="feather icon-eye default"/>
            </span>
            {useFormatMessage('UserForm.userPreview')}
          </Card.Header>
          <Card.Body className="card-content">
            {imagePreviewUrl && (
              <>
                <div className="is-user-avatar image has-max-width is-aligned-center">
                  <img
                    className="user-avatar"
                    src={imagePreviewUrl}
                    alt="User profile logo preview"
                  />
                </div>
                <hr/>
              </>
            )}

            {!isEditing && (
              <div className="field">
                <Form.Label>{emailMessage}</Form.Label>
                <div className="control is-clearfix">
                  <input
                    type="text"
                    readOnly="readOnly"
                    className="form-control no-border"
                    value={user.email}
                  />
                </div>
              </div>
            )}

            <div className="field">
              <Form.Label>
                {useFormatMessage('UserForm.name')}
              </Form.Label>
              <div className="control is-clearfix">
                <input
                  type="text"
                  readOnly="readOnly"
                  className="form-control no-border"
                  value={user.name}
                />
              </div>
            </div>

            <div className="field">
              <Form.Label>
                {useFormatMessage('UserForm.location')}
              </Form.Label>
              <div className="control is-clearfix">
                <input
                  type="text"
                  readOnly="readOnly"
                  className="form-control no-border"
                  value={user.location}
                />
              </div>
            </div>

            {!isProfile && (
              <Form.Group>
                <Form.Label>{adminMessage}</Form.Label>
                <div className="control is-clearfix">
                  {user.isAdmin ? (
                    <span className="icon">
                        <i className="feather icon-check"/>
                      </span>
                  ) : (
                    <span className="icon">
                        <i className="feather icon-x"/>
                      </span>
                  )}
                </div>
              </Form.Group>
            )}

            <Form.Group>
              <Form.Label>
                {useFormatMessage('UserForm.created')}
              </Form.Label>
              <div className="control is-clearfix">
                <p className="date">
                  {useFormatDate(user.createdAt, {
                    weekday: 'short',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  })}
                </p>
              </div>
            </Form.Group>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

UserForm.propTypes = {
  isEditing: PropTypes.bool,
  userData: PropTypes.shape({
    id: PropTypes.string,
    isAdmin: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
    createdAt: PropTypes.string.isRequired
  }),
  action: PropTypes.func.isRequired
};

export default UserForm;
