import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage, useFormatDate } from 'hooks';
import Table from 'components/Table';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import NavSearch from 'components/Layout/NavSearch';
import { fetchSchools, deleteSchool, clearSchoolsData } from '../../state/actions/schools';
import { sortDate } from '../../utils';

const countries = require('../../assets/CountryCodes');

const FieldSchool = () => {
  const { schoolList, isAdmin, error, loading, deleted } = useSelector(
    state => ({
      schoolList: state.schools.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.schools.error,
      loading: state.schools.loading,
      deleted: state.schools.deleted
    }),
    shallowEqual
  );
  const [deleteModal, setDeleteModal] = useState({
    schoolId: null,
    isOpen: false
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState();

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchSchools(true));
    }

    return () => dispatch(clearSchoolsData());
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal(prevState => ({
        schoolId: null,
        isOpen: !prevState.isOpen
      }));
    }
  }, [deleted, loading]);

  const redirect = !isAdmin && <Redirect to={paths.ROOT}/>;

  const onRemoveButtonClickHandler = schoolId => {
    setDeleteModal(prevState => ({
      schoolId,
      isOpen: !prevState.isOpen
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ schoolId: null, isOpen: false });
  };

  const onDeleteLevelHandler = () => {
    dispatch(deleteSchool(deleteModal.schoolId));
  };

  const columns = [
    {
      Header: useFormatMessage('Levels.name'),
      accessor: 'name'
    },
    {
      Header: 'Postal code',
      accessor: 'postcode'
    },
    {
      Header: 'Country',
      accessor: 'countryId',
      Cell: ({ row }) => {
        const country = countries.find( e => e.code == row.original.countryId);
        return country && country.name;
      },
    },
    {
      Header: 'Is Obsolete',
      accessor: 'isObsolete',
      Cell: ({ row }) => {
        return row.original.isObsolete ? 'Yes' : 'No';
      },
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      sortType: sortDate,
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          })}
        </small>
      )
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          {!row.original.isAdmin && (
            <div className="button-actions is-right">
              <Link
                to={`/field-schools/${row.original.id}`}
                className="btn btn-sm btn-primary"
              >
                <i className="feather icon-edit"/>
              </Link>

              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={() => onRemoveButtonClickHandler(row.original.id)}
              >
                <i className="feather icon-trash"/>
              </button>
            </div>
          )}
        </>
      ),
      disableSortBy: true
    }
  ];

  const data = search
    ? schoolList.filter(el => {
      const clonedElem = { name: el.name };
      return Object.values(clonedElem).some(field =>
        field.toLowerCase().includes(search.toLowerCase())
      );
    })
    : schoolList;

  const deleteMessage = useFormatMessage('Levels.delete');

  const confirmMessage = useFormatMessage('Levels.confirm');

  const permDeleteMessage = useFormatMessage('Levels.permDelete');

  const cancelMessage = useFormatMessage('Levels.cancel');

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteLevelHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <Card>
        <Card.Header>
          <div className='d-flex flex-row align-items-center'>
            <Card.Title as="h5">Manage schools </Card.Title>
            <NavSearch handleChange={setSearch} />
            <Link to={paths.ADD_FIELD_SCHOOL} className="btn btn-light ml-auto">
              Add new School
            </Link>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="b-table">
            {loading ? <ClipLoader/> : <Table columns={columns} data={data}/>}
            {error && 'Show error'}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default FieldSchool;
