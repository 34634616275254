import paths from './pages/Router/paths';

export default {
    items: [
        {
            id: 'navigation',
            title: 'Navigation',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/',
                    icon: 'feather icon-home',
                    badge: {
                        title: 'New',
                        type: 'label-danger'
                    },
                }
            ]
        },
        {
            id: 'level-management',
            title: 'Level Management',
            type: 'group',
            icon: 'icon-ui',
            isAdmin: true,
            children: [
                {
                    id: 'browsing-level',
                    title: 'Browsing Level',
                    type: 'collapse',
                    icon: 'feather icon-box',
                    children: [
                        {
                            id: 'manage-level',
                            title: 'Manage Level',
                            type: 'item',
                            url: paths.MANAGE_LEVEL
                        },
                        {
                            id: 'create-level',
                            title: 'Create new Level',
                            type: 'item',
                            url: paths.ADD_LEVEL
                        },

                    ]
                },

            ]
        },
        {
            id: 'customer',
            title: 'Customers',
            type: 'group',
            icon: 'icon-users',
            children: [
                {
                    id: 'student',
                    title: 'Student Profiles',
                    type: 'collapse',
                    icon: 'feather icon-user',
                    children: [
                        {
                            id: 'manage-student',
                            title: 'Manage Students',
                            type: 'item',
                            url: paths.MANAGE_STUDENT
                        },
                        {
                            id: 'create-student',
                            title: 'Create new Student',
                            type: 'item',
                            url: paths.ADD_STUDENT
                        },

                    ]
                },
                {
                    id: 'tutor',
                    title: 'Tutor Profiles',
                    type: 'collapse',
                    icon: 'feather icon-user',
                    children: [
                        {
                            id: 'manage-tutor',
                            title: 'Manage Tutors',
                            type: 'item',
                            url: paths.MANAGE_TUTOR
                        },
                        {
                            id: 'create-tutor',
                            title: 'Create new Tutor',
                            type: 'item',
                            url: paths.ADD_TUTOR
                        },

                    ]
                }
            ]
        },
        {
            id: 'statistics',
            title: 'Statistics',
            type: 'group',
            icon: 'icon-charts',
            children: [
                {
                    id: 'charts',
                    title: 'Charts',
                    type: 'item',
                    icon: 'feather icon-pie-chart',
                    url: '/statistic'
                },
            ]
        },
        {
            id: 'sales',
            title: 'Sales',
            type: 'group',
            icon: 'icon-charts',
            children: [
                {
                    id: 'order',
                    title: 'Orders',
                    type: 'item',
                    icon: 'feather icon-flag',
                    url: '/orders'
                },
            ]
        },
        {
            id: 'subject',
            title: 'Subject Setting',
            type: 'group',
            icon: 'icon-pages',
            children: [
                {
                    id: 'manage-subjects',
                    title: 'Manage Subjects',
                    type: 'item',
                    url: paths.MANAGE_SUBJECT,
                    icon: 'feather icon-book'
                },
                {
                    id: 'docs',
                    title: 'Create new Subject',
                    type: 'item',
                    url: paths.ADD_SUBJECT,
                    classes: 'nav-item',
                    icon: 'feather icon-airplay'
                },
            ]
        },
        {
            id: 'systems',
            title: 'Systems',
            type: 'group',
            icon: 'icon-lock',
            isAdmin: true,
            children: [
                {
                    id: 'users',
                    title: 'Users',
                    type: 'item',
                    url: '/users',
                    icon: 'feather icon-lock',
                },
                {
                    id: 'fields',
                    title: 'Manage fields',
                    type: 'collapse',
                    icon: 'feather icon-flag',
                    children: [
                        {
                            id: 'countries',
                            title: 'Countries',
                            type: 'item',
                            url: paths.MANAGE_COUNTRY
                        },
                        {
                            id: 'field-levels',
                            title: 'Levels',
                            type: 'item',
                            url: paths.MANAGE_FIELD_LEVEL
                        },
                        {
                            id: 'field-schools',
                            title: 'Schools',
                            type: 'item',
                            url: paths.MANAGE_FIELD_SCHOOL
                        },
                    ]
                },
              ]
        }
    ]
};
