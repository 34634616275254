import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import windowSize from 'react-window-size';

import NavGroup from './NavGroup';
import { navContentLeave } from '../../../../state/actions/preferences';

const NavContent = ({navigation}) => {

  const {
    layout, isAdmin
  } = useSelector(
    state => ({
      layout: state.preferences.layout,
      isAdmin: state.auth.userData.isAdmin
    }),
    shallowEqual
  );
  const [scrollWidth, setScrollWidth] = useState(0);
  const [prevDisable, setPrevDisable] = useState(true);
  const [nextDisable, setNextDisable] = useState(false);

  const dispatch = useDispatch();

  const onNavContentLeave = () => {
    dispatch(navContentLeave());
  };

  const scrollPrevHandler = () => {
    const wrapperWidth = document.getElementById('sidenav-wrapper').clientWidth;

    const newScrollWidth = scrollWidth - wrapperWidth;
    if (newScrollWidth < 0) {
      setScrollWidth(0);
      setPrevDisable(true);
      setNextDisable(false);
    } else {
      setScrollWidth(newScrollWidth);
      setPrevDisable(false);
    }
  };

  const scrollNextHandler = () => {
    const wrapperWidth = document.getElementById('sidenav-wrapper').clientWidth;
    const contentWidth = document.getElementById('sidenav-horizontal').clientWidth;

    let newScrollWidth = scrollWidth + (wrapperWidth - 80);
    if (newScrollWidth > (contentWidth - wrapperWidth)) {
      newScrollWidth = contentWidth - wrapperWidth + 80;
      setScrollWidth(newScrollWidth);
      setPrevDisable(false);
      setNextDisable(true);
    } else {
      setScrollWidth(newScrollWidth);
      setPrevDisable(false);
    }
  };

  const navItems = navigation.map(item => {
    if (item.isAdmin && !isAdmin) return false;
      switch (item.type) {
        case 'group':
          return <NavGroup layout={layout} key={item.id} group={item}/>;
        default:
          return false;
      }
    }
  );

  let mainContent = '';
  if (layout === 'horizontal') {
    let prevClass = ['sidenav-horizontal-prev'];
    if (prevDisable) {
      prevClass = [...prevClass, 'disabled'];
    }
    let nextClass = ['sidenav-horizontal-next'];
    if (nextDisable) {
      nextClass = [...nextClass, 'disabled'];
    }

    mainContent = (
      <div className="navbar-content sidenav-horizontal" id="layout-sidenav">
        <a href='#' className={prevClass.join(' ')} onClick={scrollPrevHandler}><span/></a>
        <div id="sidenav-wrapper" className="sidenav-horizontal-wrapper">
          <ul id="sidenav-horizontal" className="nav pcoded-inner-navbar sidenav-inner" onMouseLeave={onNavContentLeave}
              style={{ marginLeft: `-${scrollWidth}px` }}>
            {navItems}
          </ul>
        </div>
        <a href='#' className={nextClass.join(' ')} onClick={scrollNextHandler}><span/></a>
      </div>
    );
  } else {
    mainContent = (
      <div className="navbar-content datta-scroll">
        <PerfectScrollbar>
          <ul className="nav pcoded-inner-navbar">
            {navItems}
          </ul>
        </PerfectScrollbar>
      </div>
    );
  }

  return (
    <>
      {mainContent}
    </>
  );
};

export default withRouter(windowSize(NavContent));
