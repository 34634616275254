import { createReducer } from 'redux-act';

import {
  TUTOR_FETCH_DATA_INIT,
  TUTOR_FETCH_DATA_SUCCESS,
  TUTOR_FETCH_DATA_FAIL,
  TUTOR_DELETE_TUTOR_INIT,
  TUTOR_DELETE_TUTOR_SUCCESS,
  TUTOR_DELETE_TUTOR_FAIL,
  TUTOR_CLEAR_DATA,
  TUTOR_CREATE_TUTOR_INIT,
  TUTOR_CREATE_TUTOR_SUCCESS,
  TUTOR_CREATE_TUTOR_FAIL,
  TUTOR_MODIFY_TUTOR_INIT,
  TUTOR_MODIFY_TUTOR_SUCCESS,
  TUTOR_MODIFY_TUTOR_FAIL,
  TUTOR_CLEAN_UP,
  TUTOR_CLEAR_DATA_LOGOUT
} from 'state/actions/tutors';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false
};

export const tutorsReducer = createReducer(
  {
    [TUTOR_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true
    }),
    [TUTOR_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.users,
      loading: false,
      error: null
    }),
    [TUTOR_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [TUTOR_DELETE_TUTOR_INIT]: state => ({
      ...state,
      loading: true
    }),
    [TUTOR_DELETE_TUTOR_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter(elem => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true
    }),
    [TUTOR_DELETE_TUTOR_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [TUTOR_CLEAR_DATA]: state => ({
      ...initialState,
      data: state.data
    }),
    [TUTOR_CREATE_TUTOR_INIT]: state => ({
      ...state,
      loading: true
    }),
    [TUTOR_CREATE_TUTOR_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload.user),
      loading: false,
      error: null,
      success: true
    }),
    [TUTOR_CREATE_TUTOR_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [TUTOR_MODIFY_TUTOR_INIT]: state => ({
      ...state,
      loading: true
    }),
    [TUTOR_MODIFY_TUTOR_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.map(elem => {
        if (elem.id === payload.id) {
          return {
            name: payload.user.name,
            location: payload.user.location,
            id: payload.id,
            logoUrl: payload.user.logoUrl,
            createdAt: payload.user.createdAt,
            email: elem.email
          };
        }
        return elem;
      }),
      loading: false,
      error: null,
      success: true
    }),
    [TUTOR_MODIFY_TUTOR_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [TUTOR_CLEAN_UP]: state => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false
    }),
    [TUTOR_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState
    })
  },
  initialState
);
