import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import firebase from 'firebase.js';
import { checkUserData, AUTH_UPDATE_USER_DATA } from './auth';
import { BROWSING_LEVELS_CLEAR_DATA_LOGOUT } from './browsingLevels';

export const TUTOR_FETCH_DATA_INIT = createAction('TUTOR_FETCH_DATA_INIT');
export const TUTOR_FETCH_DATA_SUCCESS = createAction(
  'TUTOR_FETCH_DATA_SUCCESS'
);
export const TUTOR_FETCH_DATA_FAIL = createAction('TUTOR_FETCH_DATA_FAIL');

export const TUTOR_DELETE_TUTOR_INIT = createAction('TUTOR_DELETE_TUTOR_INIT');
export const TUTOR_DELETE_TUTOR_SUCCESS = createAction(
  'TUTOR_DELETE_TUTOR_SUCCESS'
);
export const TUTOR_DELETE_TUTOR_FAIL = createAction('TUTOR_DELETE_TUTOR_FAIL');

export const TUTOR_CLEAR_DATA = createAction('TUTOR_CLEAR_DATA');

export const TUTOR_CREATE_TUTOR_INIT = createAction('TUTOR_CREATE_TUTOR_INIT');
export const TUTOR_CREATE_TUTOR_SUCCESS = createAction(
  'TUTOR_CREATE_TUTOR_SUCCESS'
);
export const TUTOR_CREATE_TUTOR_FAIL = createAction('TUTOR_CREATE_TUTOR_FAIL');

export const TUTOR_MODIFY_TUTOR_INIT = createAction('TUTOR_MODIFY_TUTOR_INIT');
export const TUTOR_MODIFY_TUTOR_SUCCESS = createAction(
  'TUTOR_MODIFY_TUTOR_SUCCESS'
);
export const TUTOR_MODIFY_TUTOR_FAIL = createAction('TUTOR_MODIFY_TUTOR_FAIL');

export const TUTOR_CLEAN_UP = createAction('TUTOR_CLEAN_UP');

export const TUTOR_CLEAR_DATA_LOGOUT = createAction('TUTOR_CLEAR_DATA_LOGOUT');

export const fetchUsers = (all = true) => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(TUTOR_FETCH_DATA_INIT());

    let users;

    try {
      users = (
        await firebase
          .database()
          .ref('tutors')
          .once('value')
      ).val();
    } catch (error) {
      toastr.error('', error);
      return dispatch(TUTOR_FETCH_DATA_FAIL({ error }));
    }

    const usersData = users
      ? Object.entries(users).map(([key, value]) => ({
          id: key,
          ...value
        }))
      : [];

    const result = all ? usersData : usersData.filter(item => !!item.status)
    return dispatch(
      TUTOR_FETCH_DATA_SUCCESS({
        users: result
      })
    );
  };
};

const deleteLogo = oldLogo => {
  if (!oldLogo.includes('firebasestorage')) {
    return null;
  }
  const logoPath = oldLogo
    .split('users%2F')
    .pop()
    .split('?alt=media')
    .shift();
  return firebase
    .storage()
    .ref(`users/${logoPath}`)
    .delete();
};

export const deleteUser = id => {
  return async (dispatch, getState) => {
    dispatch(TUTOR_DELETE_TUTOR_INIT());
    const { locale } = getState().preferences;
    const { logoUrl } = getState()
      .tutors.data.filter(user => user.id === id)
      .pop();

    const deleteLogoTask = logoUrl ? deleteLogo(logoUrl) : null;

    const deleteUserTask = firebase
      .database()
      .ref(`tutors/${id}`)
      .remove();

    try {
      await Promise.all([deleteLogoTask, deleteUserTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        TUTOR_DELETE_TUTOR_FAIL({
          error: errorMessage
        })
      );
    }

    toastr.success('', 'The tutor was deleted.');
    return dispatch(TUTOR_DELETE_TUTOR_SUCCESS({ id }));
  };
};

export const clearUsersData = () => {
  return dispatch => {
    dispatch(TUTOR_CLEAR_DATA());
  };
};

export const clearUsersDataLogout = () => {
  return dispatch => {
    dispatch(TUTOR_CLEAR_DATA_LOGOUT());
    dispatch(BROWSING_LEVELS_CLEAR_DATA_LOGOUT());
  };
};

const uploadLogo = (uid, file) => {
  const storageRef = firebase.storage().ref();

  const fileExtension = file.name.split('.').pop();

  const fileName = `${uid}.${fileExtension}`;

  return storageRef.child(`users/${fileName}`).put(file);
};

const getLogoUrl = (uid, file) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/users%2F${uid}_200x200.${fileExtension}?alt=media`;
};

export const createUser = (data) => {
  return async (dispatch, getState) => {
    dispatch(TUTOR_CREATE_TUTOR_INIT());
    const { locale } = getState().preferences;

    let response;
    try {
      const createUserAuth = firebase
        .functions()
        .httpsCallable('httpsCreateUser');

      response = await createUserAuth({ email: data.email, userType: 'tutor' });
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        TUTOR_CREATE_TUTOR_FAIL({
          error: errorMessage
        })
      );
    }

    const { uid } = response.data;

    let uploadLogoTask = null;
    let logoUrl = null;
    if (data.file) {
      logoUrl = getLogoUrl(uid, data.file);
      uploadLogoTask = uploadLogo(uid, data.file);
    }
    delete data.file;
    delete data.isProfile;
    delete data.isEditing;
    const tutor = {
      ...data,
      logoUrl,
      id: uid,
      userType: 'tutor'
    };
    const createUserDbTask = firebase
      .database()
      .ref(`tutors/${uid}`)
      .set(tutor);

    const actionCodeSettings = {
      url: process.env.REACT_APP_LOGIN_PAGE_URL,
      handleCodeInApp: true
    };

    const sendSignInLinkToEmailTask = firebase
      .auth()
      .sendSignInLinkToEmail(data.email, actionCodeSettings);

    try {
      await Promise.all([
        uploadLogoTask,
        createUserDbTask,
        sendSignInLinkToEmailTask
      ]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        TUTOR_CREATE_TUTOR_FAIL({
          error: errorMessage
        })
      );
    }

    toastr.success('', 'Tutor created successfully');
    return dispatch(TUTOR_CREATE_TUTOR_SUCCESS({ user: response.data }));
  };
};

export const modifyUser = (data) => {
  return async (dispatch, getState) => {
    dispatch(TUTOR_MODIFY_TUTOR_INIT());
    // eslint-disable-next-line no-param-reassign
    delete data.isProfile;
    // eslint-disable-next-line no-param-reassign
    delete data.isEditing;

    const { locale } = getState().preferences;
    const { logoUrl } = getState()
          .tutors.data.filter(user => user.id === data.id)
          .pop();

    let deleteLogoTask;
    let uploadLogoTask;
    let newLogoUrl = null;
    if (data.file) {
      newLogoUrl = getLogoUrl(data.id, data.file);
      deleteLogoTask = logoUrl && deleteLogo(logoUrl);
      uploadLogoTask = uploadLogo(data.id, data.file);
    }

    const userData = {
      ...data,
      logoUrl: newLogoUrl || logoUrl || null,
      userType: 'tutor',
      createdAt: data.createdAt || new Date().toUTCString()
    };

    const updateUserDbTask = firebase
      .database()
      .ref(`tutors/${data.id}`)
      .update(userData);

    try {
      await Promise.all([deleteLogoTask, uploadLogoTask, updateUserDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        TUTOR_MODIFY_TUTOR_FAIL({
          error: errorMessage
        })
      );
    }


    toastr.success('', 'Tutor updated successfully');

    return dispatch(TUTOR_MODIFY_TUTOR_SUCCESS({ user: { ...userData, id: data.id } }));
  };
};

export const usersCleanUp = () => dispatch => dispatch(TUTOR_CLEAN_UP());
