import { createReducer } from 'redux-act';

import {
  SCHOOL_FETCH_DATA_INIT,
  SCHOOL_FETCH_DATA_SUCCESS,
  SCHOOL_FETCH_DATA_FAIL,
  SCHOOL_DELETE_SCHOOL_INIT,
  SCHOOL_DELETE_SCHOOL_SUCCESS,
  SCHOOL_DELETE_SCHOOL_FAIL,
  SCHOOL_CLEAR_DATA,
  SCHOOL_CREATE_SCHOOL_INIT,
  SCHOOL_CREATE_SCHOOL_SUCCESS,
  SCHOOL_CREATE_SCHOOL_FAIL,
  SCHOOL_MODIFY_SCHOOL_INIT,
  SCHOOL_MODIFY_SCHOOL_SUCCESS,
  SCHOOL_MODIFY_SCHOOL_FAIL,
  SCHOOL_CLEAN_UP,
  SCHOOL_CLEAR_DATA_LOGOUT
} from '../../actions/schools';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false
};

export const fieldSchoolsReducer = createReducer(
  {
    [SCHOOL_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true
    }),
    [SCHOOL_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.schools,
      loading: false,
      error: null
    }),
    [SCHOOL_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [SCHOOL_DELETE_SCHOOL_INIT]: state => ({
      ...state,
      loading: true
    }),
    [SCHOOL_DELETE_SCHOOL_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter(elem => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true
    }),
    [SCHOOL_DELETE_SCHOOL_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [SCHOOL_CLEAR_DATA]: state => ({
      ...initialState,
      data: state.data
    }),
    [SCHOOL_CREATE_SCHOOL_INIT]: state => ({
      ...state,
      loading: true
    }),
    [SCHOOL_CREATE_SCHOOL_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload),
      loading: false,
      error: null,
      success: true
    }),
    [SCHOOL_CREATE_SCHOOL_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [SCHOOL_MODIFY_SCHOOL_INIT]: state => ({
      ...state,
      loading: true
    }),
    [SCHOOL_MODIFY_SCHOOL_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.map(elem => {
        if (elem.id === payload.id) {
          return payload;
        }
        return elem;
      }),
      loading: false,
      error: null,
      success: true
    }),
    [SCHOOL_MODIFY_SCHOOL_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [SCHOOL_CLEAN_UP]: state => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false
    }),
    [SCHOOL_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState
    })
  },
  initialState
);
