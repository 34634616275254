import { createReducer } from 'redux-act';

import {
  SUBJECT_FETCH_DATA_INIT,
  SUBJECT_FETCH_DATA_SUCCESS,
  SUBJECT_FETCH_DATA_FAIL,
  SUBJECT_DELETE_SUBJECT_INIT,
  SUBJECT_DELETE_SUBJECT_SUCCESS,
  SUBJECT_DELETE_SUBJECT_FAIL,
  SUBJECT_CLEAR_DATA,
  SUBJECT_CREATE_SUBJECT_INIT,
  SUBJECT_CREATE_SUBJECT_SUCCESS,
  SUBJECT_CREATE_SUBJECT_FAIL,
  SUBJECT_MODIFY_SUBJECT_INIT,
  SUBJECT_MODIFY_SUBJECT_SUCCESS,
  SUBJECT_MODIFY_SUBJECT_FAIL,
  SUBJECT_CLEAN_UP,
  SUBJECT_CLEAR_DATA_LOGOUT
} from '../../actions/subjects';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false
};

export const fieldSubjectsReducer = createReducer(
  {
    [SUBJECT_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true
    }),
    [SUBJECT_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.subjects,
      loading: false,
      error: null
    }),
    [SUBJECT_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [SUBJECT_DELETE_SUBJECT_INIT]: state => ({
      ...state,
      loading: true
    }),
    [SUBJECT_DELETE_SUBJECT_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter(elem => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true
    }),
    [SUBJECT_DELETE_SUBJECT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [SUBJECT_CLEAR_DATA]: state => ({
      ...initialState,
      data: state.data
    }),
    [SUBJECT_CREATE_SUBJECT_INIT]: state => ({
      ...state,
      loading: true
    }),
    [SUBJECT_CREATE_SUBJECT_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.concat(payload),
      loading: false,
      error: null,
      success: true
    }),
    [SUBJECT_CREATE_SUBJECT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [SUBJECT_MODIFY_SUBJECT_INIT]: state => ({
      ...state,
      loading: true
    }),
    [SUBJECT_MODIFY_SUBJECT_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.map(elem => {
        if (elem.id === payload.id) {
          return payload;
        }
        return elem;
      }),
      loading: false,
      error: null,
      success: true
    }),
    [SUBJECT_MODIFY_SUBJECT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [SUBJECT_CLEAN_UP]: state => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false
    }),
    [SUBJECT_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState
    })
  },
  initialState
);
