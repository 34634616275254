import React, { useEffect, useState } from 'react';
import { Card, Accordion, Button } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { WithContext as ReactTags } from 'react-tag-input';

import { clearUsersData, fetchUsers } from '../../state/actions/tutors';
import './TutorMapping.scss';
import { mappingUser } from '../../state/actions/students';

const KeyCodes = {
  comma: 188
};

const delimiters = [KeyCodes.comma];

const TutorMapping = (props) => {
  const { tutorList, isAdmin, subjectList } = useSelector(
    state => ({
      tutorList: state.tutors.data,
      isAdmin: state.auth.userData.isAdmin,
      subjectList: state.subjects.data
    }),
    shallowEqual
  );
  const { userData, subjectId } = props;
  const tutorMapping = userData.tutorMapping || {};
  if (!tutorMapping[subjectId]) {
    tutorMapping[subjectId] = { suggested: [], confirmed: [] };
  }
  const tutorSubjectMapping = tutorMapping[subjectId];

  const suggestedTutors = tutorSubjectMapping.suggested && tutorList.filter(item => tutorSubjectMapping.suggested.indexOf(item.id) >= 0) || [];
  const confirmedTutors = tutorSubjectMapping.confirmed && tutorList.filter(item => tutorSubjectMapping.confirmed.indexOf(item.id) >= 0) || [];

  const initTags = suggestedTutors.map(item => ({ id: item.id, text: item.name || item.email }));

  const initTutors = tutorList.filter(item => tutorSubjectMapping.suggested.indexOf(item.id) < 0).map(item => ({
    id: item.id,
    text: item.name || item.email
  }));

  const initConfirmedTags = confirmedTutors.map(item => ({ id: item.id, text: item.name || item.email }));

  const dispatch = useDispatch();

  const [tags, setTags] = useState(initTags);
  const [suggestions, setSuggestions] = useState(initTutors);

  const [confirmedTags, setConfirmedTags] = useState(initConfirmedTags);
  const [removedTags, setRemovedTags] = useState({});

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchUsers());
    }

    return () => dispatch(clearUsersData());
  }, [dispatch, isAdmin]);

  const saveSuggestedTutor = (data) => {
    const ids = data.map(tag => tag.id);
    tutorSubjectMapping.suggested = ids;
    dispatch(mappingUser({ studentData: { ...userData, tutorMapping } }));
  };

  const saveConfirmedTutor = (data) => {
    let tutorData = {};
    if (data[0]) {
      tutorSubjectMapping.confirmed = [data[0].id];
      tutorData = tutorList.find(item => item.id === data[0].id);
      if (!tutorData.confirmedStudents) {
        tutorData.confirmedStudents = {};
      }

      if (!tutorData.confirmedStudents[userData.id]) {
        tutorData.confirmedStudents[userData.id] = [subjectId];
      } else if (tutorData.confirmedStudents[userData.id].indexOf(subjectId) < 0) {
        tutorData.confirmedStudents[userData.id].push(subjectId);
      }

    } else {
      tutorSubjectMapping.confirmed = [];
      if (removedTags.id) {
        tutorData = tutorList.find(item => item.id === removedTags.id);
        if (tutorData.confirmedStudents && tutorData.confirmedStudents[userData.id]) {
          const index = tutorData.confirmedStudents[userData.id].indexOf(subjectId);
          if (index !== -1) {
            tutorData.confirmedStudents[userData.id].splice(index, 1);
          };
          if (tutorData.confirmedStudents[userData.id].length === 0) {
            delete tutorData.confirmedStudents[userData.id];
          }
        }
      }
    }

    dispatch(mappingUser({ studentData: { ...userData, tutorMapping }, tutorData }));
  };

  useEffect(() => {
    if (tags !== initTags) {
      saveSuggestedTutor(tags);
    }
  }, [tags]);

  useEffect(() => {

    if (confirmedTags !== initConfirmedTags) {
      saveConfirmedTutor(confirmedTags);
    }

  }, [confirmedTags]);

  const handleDelete = (i) => {
    const deletedTag = tags[i];
    setTags(prevTags => prevTags.filter((tag, index) => index !== i));
    setSuggestions(prevSuggestions => [...prevSuggestions, deletedTag]);
  };

  const handleAddition = (tag) => {
    setTags(prevTags => [...prevTags, tag]);
    setSuggestions(prevSuggestions => prevSuggestions.filter(item => item.id !== tag.id));
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
  };

  const handleConfirmDelete = (i) => {
    setRemovedTags(confirmedTags[i]);
    setConfirmedTags([]);
  };

  const handleConfirmAddition = (tag) => {
    setConfirmedTags([tag]);
  };

  const subject = subjectList.find(item => item.id === subjectId);
  return (
    subject ?
      <Card className="tutor-mapping">
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={subjectId}>
            {subject.name}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={subjectId}>
          <Card.Body>
            <h5>Suggested Tutors</h5>
            <ReactTags tags={tags}
                       suggestions={suggestions}
                       delimiters={delimiters}
                       handleDelete={handleDelete}
                       handleAddition={handleAddition}
                       handleDrag={handleDrag}
                       allowDeleteFromEmptyInput={false}
                       inputFieldPosition="inline"
                       placeholder='Add tutors'
                       autocomplete
            />
            <hr/>
            <h5>Confirmed Tutors</h5>
            <ReactTags tags={confirmedTags}
                       suggestions={tags}
                       delimiters={delimiters}
                       handleDelete={handleConfirmDelete}
                       handleAddition={handleConfirmAddition}
                       allowDeleteFromEmptyInput={false}
                       inputFieldPosition="inline"
                       placeholder='Add tutors'
                       autocomplete
            />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      : null
  );
};

export default TutorMapping;
