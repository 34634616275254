import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Switch from 'react-bootstrap/Switch';
import ClipLoader from 'react-spinners/ClipLoader';
import Table from 'components/Table';
import { fetchCountries, setCountryObsolete, unSetCountryObsolete, clearCountrysData } from '../../state/actions/countries';
import paths from 'pages/Router/paths';
import NavSearch from 'components/Layout/NavSearch';

const Countries = () => {
  const { countriesList, isAdmin, error, loading } = useSelector(
    state => ({
      countriesList: state.countries.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.countries.error,
      loading: state.countries.loading,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [search, setSearch] = useState();

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchCountries(true));
    }

    return () => dispatch(clearCountrysData());
  }, [dispatch, isAdmin]);

  const toggleIsObsolete = (country) => {
    if (country.id && country.isObsolete) {
      dispatch(unSetCountryObsolete(country));
    } else {
      dispatch(setCountryObsolete({...country, isObsolete: true}));
    }
  }


  const redirect = !isAdmin && <Redirect to={paths.ROOT}/>;

  const columns = [
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Code',
      accessor: 'code',
    },
    {
      Header: 'Dial code',
      accessor: 'dial_code'
    },
    {
      Header: 'Is Obsolete',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          {!row.original.isAdmin && (
            <Switch
              type="switch"
              id={`country_${row.original.code}`}
              checked={row.original.isObsolete}
              label=""
              onChange={() => toggleIsObsolete({...row.original, isObsolete: row.original.isObsolete})}
            />
          )}
        </>
      ),
      disableSortBy: true
    }
  ];

  const data = search
    ? countriesList.filter(el => {
      const clonedElem = { ...el };
      return Object.values(clonedElem).some(field =>
        field && field.toLowerCase().includes(search.toLowerCase())
      );
    })
    : countriesList;
  return (
    <>
      {redirect}

      <Card>
        <Card.Header>
          <div className='d-flex flex-row align-items-center'>
            <Card.Title as="h5">Manage Countries</Card.Title>
            <NavSearch handleChange={setSearch} />
          </div>
        </Card.Header>
        <Card.Body>
          <div className="b-table">
            {loading ? <ClipLoader/> : <Table columns={columns} data={data}/>}
            {error && 'Show error'}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Countries;
